import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'core/store';
import { initialState } from './data';
import { ScheduleBranchState, ScheduleBranchDataType } from './types';

export const scheduleBranchSlice = createSlice({
    name: 'scheduleBranch',
    initialState,
    reducers: {
        resetSchedule: () => initialState,
        initSchedule: (state, action: PayloadAction<ScheduleBranchState>) => {
            state = initialState;
        },
        setSchedule: (state, action: PayloadAction<ScheduleBranchDataType[]>) => {
            state.data = action.payload;
        },
    },
});

export const { 
    resetSchedule,
} = scheduleBranchSlice.actions;

//export default scheduleBranchSlice.reducer;

export const scheduleBranchSelector = {
    data: (s: RootState) => s.scheduleBranch.data,
};