import { ItemDataSearchProps } from './index';

export const changeActiveDataSearch = (id: number, data?: ItemDataSearchProps[]) => {
  return (
    data?.map((item) => {
      if (item.id === id) {
        return { ...item, active: !item.active };
      }
      return item;
    }) || []
  );
};

export const changeActiveConnectData = (item: ItemDataSearchProps, data: ItemDataSearchProps[]) => {
  const nextData = [...data];

  if (item.active) {
    nextData.push(item);
  } else {
    return nextData.filter((i) => i.id !== item.id);
  }

  return nextData;
};
