import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'components/input';
import { Textarea } from 'components/textarea';
import { AddPhoto } from 'features/addPhoto';
import { useAppSelector } from 'core/hooks';
import { workplaceSelector } from '../workplaceSlice';
import { useTabsContext } from '../context';
import { transformDataSearch } from 'pages/specialist/utils';

const initValidData: Record<string, boolean> = {
  title: true,
  address: true,
};

type AboutPlaceProps = {
  setChanged: (isChanged: boolean) => void;
};

export const AboutPlace: React.FC<AboutPlaceProps> = ({ setChanged }) => {
  const workplace = useAppSelector(workplaceSelector.workplace);
  const [validData, setValidData] = useState<Record<string, boolean>>(initValidData);
  const { state: stateTabs, setState: setStateTabs } = useTabsContext();
  const data = useMemo(() => stateTabs?.workplace?.data, [stateTabs?.workplace?.data]);

  useEffect(() => {
    setStateTabs?.((prev) => ({
      ...prev,
      workplace: {
        ...prev?.workplace,
        onIsValid: () => {
          const newValid = Object.entries(validData).reduce(
            (acc, [key, _]) => ({ ...acc, [key]: !!prev?.workplace?.data?.[key] }),
            {},
          );
          setValidData(newValid);
          return !Object.entries(newValid)
            .map(([_, item]) => item)
            .includes(false);
        },
      },
    }));
  }, [validData, setValidData, data]);

  useEffect(() => {
    if (workplace) {
      setStateTabs?.((prev) => ({
        ...prev,
        workplace: {
          ...prev?.workplace,
          data: {
            ...workplace,
            services: transformDataSearch(workplace?.services as Record<string, any>[], true) || [],
          },
        },
      }));
    }
  }, [workplace]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setStateTabs?.((prev) => ({
      ...prev,
      workplace: {
        ...prev?.workplace,
        data: {
          ...prev?.workplace?.data,
          [e.target.name]: e.target.value,
        },
      },
    }));

    if (Object.keys(validData).includes(e.target.name)) {
      setValidData({ ...validData, [e.target.name]: true });
    }
    setChanged(true);
  };

  const handleOnChangePhoto = (file: Record<string, any>) => {
    setStateTabs?.((prev) => ({
      ...prev,
      workplace: {
        ...prev?.workplace,
        data: {
          ...prev?.workplace?.data,
          image: file,
        },
      },
    }));
    setChanged(true);
  };

  return (
    <Content>
      <Left>
        <Title>Данные</Title>
        <WrapperInput>
          <Input
            onChange={handleOnChange}
            value={data?.title || ''}
            valid={validData.title}
            name={'title'}
            placeholder={'Название рабочего места'}
          />
        </WrapperInput>
        <WrapperInput>
          <Input
            onChange={handleOnChange}
            value={data?.address || ''}
            valid={validData.address}
            name={'address'}
            placeholder={'Адрес'}
          />
        </WrapperInput>
        <WrapperInput>
          <Textarea
            onChange={handleOnChange}
            value={data?.description || ''}
            name={'description'}
            placeholder={'Описание'}
          />
        </WrapperInput>
      </Left>
      <Right>
        <Title>Фотография</Title>
        <AddPhoto
          onChange={handleOnChangePhoto}
          urlImg={data?.image?.file_path}
          title='Загрузить'
          height={148}
          width={148}
        />
      </Right>
    </Content>
  );
};
const WrapperInput = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
const Left = styled.div`
  width: 320px;
  margin-right: 40px;
`;
const Right = styled.div`
  width: 340px;
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 16px;
`;

const Content = styled.div`
  padding: 24px 20px;
  background: #ffffff;
  display: flex;
  align-items: flex-start;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 8px;
`;
