import React from 'react';
import styled from 'styled-components';
import { ItemTitleHead } from './itemTitleHead';
import { Moment } from 'moment';

type HeaderProps = {
  date: Moment[];
};

export const Header: React.FC<HeaderProps> = ({ date }) => {
  return (
    <Content>
      <div />
      {date.map((date, index) => (
        <ItemTitleHead key={date.toString()} date={date} index={index} />
      ))}
    </Content>
  );
};

const Content = styled.div`
  display: grid;
  grid-template-columns: 73px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
`;
