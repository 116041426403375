import createRequestRest from 'api/createRequestRest';
import { put } from 'redux-saga/effects';

import { branchSlice } from '../branchSlice';

function* prepareRequest(payload: any) {
  return payload?.payload?.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  payload?.payload?.success?.();
  yield;
}

function* prepareFailure(res: any) {
  yield;
}

export const deleteBranch = () => {
  return createRequestRest({
    url: '/api/v1/branch/remove',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: branchSlice.actions.postDeleteBranchRequest,
    method: 'delete',
  });
};
