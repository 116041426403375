import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from 'components/input';
import { SwitchText } from 'components/switchText';
import { Textarea } from 'components/textarea';
import { CheckboxService } from 'components/checkboxService';
import { IconPlus } from 'icons';

const noop = () => undefined;

export const Discount: React.FC = () => {
  const [switchText, setSwitchText] = useState(false);
  return (
    <>
      <Content>
        <Title>Праметры скидки</Title>
        <Body>
          <Left>
            <WrapperBlock>
              <WrapperInput>
                <Input placeholder={'Скидка'} />
              </WrapperInput>
              <WrapperText>
                <SwitchText
                  onText={'Рублей'}
                  offText={'Процентов'}
                  active={switchText}
                  onClick={() => setSwitchText((prevState) => !prevState)}
                />
              </WrapperText>
            </WrapperBlock>
            <WrapperBlock>
              <WrapperDataPicker>{/*<DataPicker placeholder={'Дата начала'} />*/}</WrapperDataPicker>
              <Dash>—</Dash>
              <WrapperDataPicker>{/*<DataPicker placeholder={'Дата окончания'} />*/}</WrapperDataPicker>
            </WrapperBlock>
          </Left>
          <Right>
            <Textarea placeholder={'Комментарий'} />
          </Right>
        </Body>
        <CheckboxService title={'Постоянная'} active={false} setActive={noop} />
      </Content>
      <AddDiscount>
        <WrapperIcon>
          <IconPlus />
        </WrapperIcon>
        <AddTitle>Добавить скидку</AddTitle>
      </AddDiscount>
    </>
  );
};

const WrapperDataPicker = styled.div`
  width: 215px;
`;
const WrapperText = styled.div`
  div {
    height: 100%;
    display: flex;
    align-items: center;
  }
  & > div {
    height: 40px;
  }
`;
const AddTitle = styled.div`
  color: #6471e0;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`;
const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  svg {
    path {
      fill: #6471e0;
    }
  }
`;
const AddDiscount = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Dash = styled.div`
  padding: 0 8px;
`;
const WrapperInput = styled.div`
  margin-right: 16px;
`;
const WrapperBlock = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  margin-bottom: 16px;
`;
const Right = styled.div``;
const Left = styled.div``;
const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #282a2f;
  margin-bottom: 16px;
`;
const Content = styled.div`
  width: 984px;
  padding: 24px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 16px;
`;
