import React from 'react';
import styled from 'styled-components';
import { IconClose } from 'icons';
import { ScheduleRowProps } from '../types';

export const ButtonRemoveRow: React.FC<ScheduleRowProps> = ({number, onDelete}) => {
  return (
    <Content>
      <IconClose onClick={() => onDelete?.(number || 0)} title='Удалить'/>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    path {
      stroke: var(--color-red);
    }
    &:hover {opacity: .85}
  }
`;