import createRequestRest from 'api/createRequestRest';
import { put } from 'redux-saga/effects';
import { kanbanSlice } from '../kanbanSlice';
import { transformColumn } from '../utils';

function* prepareRequest(data: any) {
  return data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  const columns = transformColumn(response?.column);
  const archive = response?.column?.find((item: Record<string, any>) => item.is_archive);
  // const data = transformKart(response);
  yield put(kanbanSlice.actions.getAllTodoListSuccess({ data: response?.data, columns, archive }));
}

function* prepareFailure(res: any) {
  yield;
}

export const getAllTodoList = () => {
  return createRequestRest({
    url: '/api/v1/todolist/getAll',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: kanbanSlice.actions.getAllTodoListRequest,
    method: 'get',
  });
};
