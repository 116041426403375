import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BlockTabsContent, TitleBlock } from 'components/style';
import { SwitchText } from 'components/switchText';
import { HeaderTable } from '../header';
import { getHistoryRowsData, historyHeader, transformHistoryRow } from '../heplers';
import { Row } from '../row';
import { TableHistory } from '../historyTable';
import { HistoryTable } from '../types';
import { useTabsContext } from './context';

export const History: React.FC = () => {
  const [switchText, setSwitchText] = useState(false);
  const { state: stateTabs, setState: setStateTabs } = useTabsContext();

  const [dataRows, setDataRows] = useState<HistoryTable[][]>([]);

  useEffect(() => {
    if (stateTabs?.general?.data?.todolistItems) {
      const rows: HistoryTable[][] = stateTabs?.general?.data?.todolistItems.map((item: Record<string, any>) =>
        getHistoryRowsData(historyHeader, item),
      );
      setDataRows(rows);
    }
    return;
  }, [stateTabs?.general?.data?.todolistItems]);

  console.log(dataRows);

  return (
    <>
      <Content>
        <BlocContentModel>
          <TitleBlock>История клиента</TitleBlock>
          <SwitchWrapper>
            <SwitchText
              onText={'Записи'}
              offText={'Заказы'}
              active={switchText}
              onClick={() => setSwitchText((prevState) => !prevState)}
            />
          </SwitchWrapper>
          {dataRows && !!dataRows?.length ? (
            <>
              <TableHistory data={dataRows} />
            </>
          ) : (
            <TextNotData>Данные не найдены</TextNotData>
          )}
        </BlocContentModel>
      </Content>
    </>
  );
};
const TextNotData = styled.div`
  width: 100%;
  display: inline-block;
  padding: 20px;
  font-size: 14px;
  text-align: center;
`;
const SwitchWrapper = styled.div`
  width: max-content;
  margin: 24px 0;
  div {
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
  }
  & > div {
    height: 40px;
  }
`;
const Content = styled.div`
  width: 984px;
`;
const Wrapper = styled.div`
  min-width: 450px;
`;
const WrapperBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  padding: 20px;
`;
const BlocContentModel = styled.div`
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 8px;
  margin-bottom: 8px;
`;
