import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

type PaginationProps = {
  size: number;
  count: number;
  page: number;
  onChange?: (value: number) => void;
};

export const PaginationBack: React.FC<PaginationProps> = ({ size, count, page, onChange }) => {
  const [dataItems, setDataItems] = useState<Record<string, any>>();

  useEffect(() => {
    const sizeNext = Math.ceil(count / size);
    const items: any[] = [].map.call('_'.repeat(sizeNext), (a, i) => i + 1);

    const dataObj: Record<string, any> = items.reduce(
      (acc, item, index) => ({
        ...acc,
        [index]: { id: index + 1, slice: item, active: index + 1 === page },
      }),
      {},
    );
    setDataItems(dataObj);
  }, [size, count, page]);

  const handleClickItem = (id: string) => () => {
    onChange?.(dataItems?.[id]?.id);
    setDataItems((prev: Record<string, any>) =>
      Object.entries(prev).reduce((acc, [key, item]) => {
        if (key === id) {
          return { ...acc, [key]: { ...item, active: true } };
        } else {
          return { ...acc, [key]: { ...item, active: false } };
        }
      }, {}),
    );
  };

  return (
    <Content>
      {dataItems &&
        Object.entries(dataItems).map(([key, item]) => (
          <Item key={key} active={item.active} onClick={handleClickItem(key)}>
            {item.id}
          </Item>
        ))}
    </Content>
  );
};

const Item = styled.div<{ active: boolean }>`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--color-blue);
  cursor: pointer;
  ${({ active }) =>
    active
      ? css`
          border: 1px solid var(--color-blue);
          color: var(--color-black-light);
        `
      : css`
          border: 1px solid #ffffff00;
        `}
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;
