import React from 'react';
import styled from 'styled-components';
import { IconPlus } from 'icons';
import { ButtonLink } from 'components/buttons';

type ButtonAddRowProps = {
  title?: string;
  disabled?: boolean;
  onAdd?: () => void;
};

export const ButtonAddRow: React.FC<ButtonAddRowProps> = ({title, disabled, onAdd}) => {
  return (
      <ButtonLink onClick={onAdd} disabled={disabled || false}>
        <WrapperIconPlus><IconPlus/></WrapperIconPlus>
        {title}
      </ButtonLink>
  );
};

const WrapperIconPlus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    path {
      stroke: var(--color-blue);
    }
  }
`;