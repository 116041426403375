import React from 'react';
import styled from 'styled-components';
import { weekDays } from 'features/schedule/data';

export const WeekDays: React.FC = () => {  
  return (
    <Content>
      {weekDays.map(item => <WeekDay key={item.name}>{item.name}</WeekDay>)}
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap:5px;
  padding: 0;
  width: 100%;
`;
const WeekDay = styled.div`
  text-align: left;
  padding: 0;
  color: #6B6F76;
  font-size: 12px;
  width: 160px;
`;