import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Dropdown as DropdownAnt } from 'antd';

export type ItemMenu = {
  key: string;
  label: string;
  onClick: () => void;
};

type DropdownProps = {
  content: any;
  menu: ItemMenu[];
};

export const Dropdown: React.FC<DropdownProps> = ({ content, menu }) => {
  const items = useMemo(() => menu.map((item) => ({ ...item, label: <ItemLabel>{item.label}</ItemLabel> })), [menu]);

  return <DropdownAnt menu={{ items }}>{content}</DropdownAnt>;
};

const ItemLabel = styled.div`
  padding: 5px;
  &:hover {
    background: var(--color-grey-light);
  }
`;
