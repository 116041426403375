import React, {useState, useEffect} from 'react';
import styled, {css} from 'styled-components';
import moment, { Moment } from 'moment';
import { DayItem } from './dayItem';
import { Preloader } from 'components/preloader';
import { mockScheduleItems, mockSheduleSpecialists } from '../schedule/data';

type CalendarDayProps = {
  day: Moment;
  prev?: boolean;
}

export const CalendarDay: React.FC<CalendarDayProps> = ({day, prev}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [showSpecialists, setShowSpecialists] = useState<boolean>(false);
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    setItems(mockScheduleItems.filter(i => i.day === +moment(day).format('DD') && i.month === +moment(day).format('MM') && i.year === +moment(day).format('YYYY')));
  }, [mockScheduleItems, day]);

  useEffect(() => {
    setShowButton(items.length > 0)
  }, [items]);

  const handleAdd = (item: any) => {
    setLoading(true);
    setShowSpecialists(false);
    
    const _items = mockScheduleItems.filter(i => 
      i.day === +moment(day).format('DD') 
      && i.month === +moment(day).format('MM')
      && i.year === +moment(day).format('YYYY')
    );
    if (_items.length) {
      mockScheduleItems.filter(i => 
        i.day === +moment(day).format('DD') 
        && i.month === +moment(day).format('MM')
        && i.year === +moment(day).format('YYYY')
      )[0].data.push(item);
    } else {
      const newItem = {
        month: +moment(day).format('MM'),
        day: +moment(day).format('DD'),
        week: null,
        year: +moment(day).format('YYYY'),
        data: [item]
      };
      mockScheduleItems.push(newItem);
      setItems(mockScheduleItems.filter(i => i.day === +moment(day).format('DD') && i.month === +moment(day).format('MM') && i.year === +moment(day).format('YYYY')));
    }
    setLoading(false);
  }

  const handleRemove = (item) => {
    setLoading(true);
    mockScheduleItems.forEach((m,i) => {
      if (m.day === +moment(day).format('DD') 
        && m.month === +moment(day).format('MM')
        && m.year === +moment(day).format('YYYY')
      ) {
        m.data = m.data.filter((d,j) => {
          return d.name !== item.name
            && d.grafik.from !== item.grafik.from
            && d.grafik.to !== item.grafik.to;
        });
      }
    });
    setItems(mockScheduleItems.filter(i => i.day === +moment(day).format('DD') && i.month === +moment(day).format('MM') && i.year === +moment(day).format('YYYY')));
    setLoading(false);
  }

  return (
    <Content prev={prev || false} onMouseLeave={() => setShowSpecialists(false)}>
      <Title>{moment(day).format('DD')}{loading && <Preloader loading={loading} align="top" />}</Title>
      <Body onMouseOver={() => setShowButton(true)} onMouseOut={() => setShowButton(items.length > 0)}>
        {items.map(el => el.data.map((d,i) => 
          <DayItem key={i} itemsCount={el.data.length} item={d} prev={prev} onRemove={() => handleRemove(d)}/>
        ))}
        {!prev && showButton && !showSpecialists && 
        <AddButton title="Добавить" onClick={() => setShowSpecialists(true)}>
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 7V17" stroke="#6471E0" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M7.5 12L17.5 12" stroke="#6471E0" strokeWidth="1.5" strokeLinecap="round"/>
          </svg>
        </AddButton>}
        {!prev && showSpecialists &&
          <SelectSpecialist>
            {mockSheduleSpecialists.map((item,i) => 
              <li key={i} onClick={() => handleAdd(item)}>{item.name}</li>
            )}
          </SelectSpecialist>
        }
      </Body>
    </Content>
  );
};

const Content = styled.div<{prev: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  width: 160px;
  min-height: 110px;
  border: 1px #f0f0f0 solid;
  border-radius: 4px;
  padding: 3px;
  font-size: 12px;
  color: #6B6F76;
  ${({ prev }) => {
    if (prev) {
      return css`
        pointer-events: none;
        opacity: 0.85;
      `;
    }
  }};
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10% 0;
  width: 100%;
`;
const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 3px;
  width: 100%;
  height: 100%;
  padding: 5px;
`;
const AddButton = styled.button`
  width: 100%;
  padding: 0;
  text-align: center;
  background-color: #F0F3F9;
  &:hover {
    cursor: pointer;
    opacity: .75;
  }
`;
const SelectSpecialist = styled.ul`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 24px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 3px;
  width: 100%;
  padding: 8px;
  margin: 0 auto;
  text-align: center;
  background-color: #FFF;
  border: 1px #f0f0f0 solid;
  box-shadow: 2px 4px 16px rgba(0,0,0,0.25);
  border-radius: 5px;
  & > li {
    background-color: #FFEEC0;
    color: #282A2F;
    padding: 3px;
    &:hover {
      cursor: pointer;
      background-color: #E6DAF9;
    }
  }
`;
