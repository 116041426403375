import styled from 'styled-components';

const Flex = styled.div`
    display: flex;
    gap: 12px;
    width: 100%;
`;

const FlexColumn = styled(Flex)`
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
`;

const FlexRow = styled(Flex)`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const ValidationError = styled(FlexRow)`
    justify-content: center;
    font-size: 10px;
    text-align: center;
    color: var(--color-red);
`;

const ScheduleTypeBlock = styled(FlexColumn)`
    justify-content: center;
    gap: 16px;
    padding: 24px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
`;

const ScheduleContainer = styled(FlexRow)`
    align-items: flex-start;
    justify-content: space-between;
    gap: 32px;
    & > *:nth-child(1) {flex: 1 0 33%;}
    & > *:nth-child(2) {flex: 0 1 auto;}
`;

const ScheduleRowTitle = styled.h4`
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: var(--color-black);
`;

const ScheduleRowBlock = styled(FlexColumn)`
    justify-content: center;
    gap: 16px;
`;

const WeekDaysBlock = styled(FlexRow)`
    align-items: stretch;
    justify-content: space-between;
    gap: 16px;
    & > *:nth-child(1) {flex: 1 0 10%;}
    & > *:nth-child(2) {flex: 0 1 auto;}  
`;

const WeekDaysScheduleRowsBlock = styled(ScheduleRowBlock)`
    gap: 24px;
`;

export {
    FlexColumn, 
    FlexRow, 
    ValidationError,
    ScheduleTypeBlock,
    ScheduleContainer,
    ScheduleRowTitle,
    ScheduleRowBlock,
    WeekDaysBlock,
    WeekDaysScheduleRowsBlock,
};