import React from 'react';
import styled from 'styled-components';
import { HeaderTable } from './header';
import { historyHeader } from './heplers';
import { HistoryRow } from './historyRow';
import { HistoryTable } from './types';

const GRID_TABLE = '.15fr .40fr .30fr .10fr .10fr .20fr';

type TableProps = {
  data: HistoryTable[][];
};
export const TableHistory: React.FC<TableProps> = ({ data }) => {
  return (
    <Content>
      <HeaderTable grid={GRID_TABLE} data={historyHeader} />
      {data?.map((row, idx) => (
        <HistoryRow key={row?.[idx]?.id} grid={GRID_TABLE} data={row} />
      ))}
    </Content>
  );
};

const Content = styled.div``;
