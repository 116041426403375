import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'components/input';
import { DefaultButton, WhiteButton } from 'components/buttons';

export const CodeButton: React.FC = () => {
  const [domain, setDomain] = useState<string | null>('mysite.ru');
  return (
    <Content>
      <WrapperRow>
        <Input
            onChange={(e: ChangeEvent<HTMLInputElement>) => setDomain(e.target.value)}
            value={domain || ''}
            name={'domain'}
            placeholder={'Адрес сайта (домен)'}
            isClear
          />
      </WrapperRow>
      <Title>
        Код необходимо скопировать на страницы вашего сайта. Копируйте код только если полностью осознаете, что делаете
      </Title>
      <WrapperRow>
        <CodeScript>
          {`<script src="http://193.176.79.134/widget.min.js"></script>`}
        </CodeScript>
      </WrapperRow>
      <CodeText>
        {`/* button */ /* Auto layout */ display: flex; flex-direction: row; justify-content: center; align-items: center;
        padding: 10px 16px; gap: 8px; width: 102px; height: 32px; /* button blue */ background: #6471E0; border-radius:
        4px; /* Inside auto layout */ flex: none; order: 0; flex-grow: 0;`}
      </CodeText>
      {/*<Dev><h4>В разработке</h4></Dev>*/}

      <WrapperButtons>
        <DefaultButton>Сохранить</DefaultButton>
        <WhiteButton>Отменить</WhiteButton>
      </WrapperButtons>
    </Content>
  );
};

const CodeText = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: var(--color-black);
  width: 100px;
  display: none;
`;
const CodeScript = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: var(--color-black);
  border: 1px #dfe1e4 solid;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 2em;
  padding: 12px;
`;
const Title = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: var(--color-black);
  margin-bottom: 24px;
`;
const Content = styled.div`
  padding: 40px 60px;
  position: relative;
  //pointer-events: none;
  //opacity: 0.65;
`;
const WrapperRow = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-column-gap: 32px;
  grid-template-columns: 1fr 100px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
const WrapperButtons = styled.div`
  margin-top: 100px;
  display: grid;
  grid-template-columns: 150px 150px;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
`;
const Dev = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > h4 {
    text-align: center;
    opacity: 1;
    color: #461684;
    padding: 2em;
    background-color: rgba(255,255,255,0.8);
  }
`;
