import axios from 'axios';
import { message } from 'antd';
import Cookies from 'js-cookie';

const instance = axios.create({
  baseURL: 'https://anttest.bergmanit.ru', //dev
  // baseURL: 'http://localhost:3000',
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
  },
});

const makeRequest = async (
  method = 'get',
  url = '/',
  data = {}, //данные для запроса
  isQuery,
  isFormData = false,
  isFile,
  headers = null, // заголовки
  allResponse = false, // получить весь ответ, а не только внутренний
  otherParamsRequest = {}, // дополнительные параметры
  isNull = false,
) => {
  const newRequest = {
    method,
    url,
    ...otherParamsRequest,
  };
  //дополнительные заголовки
  if (headers) newRequest.headers = headers;

  //вырезаем все поля с null
  let newData = {};
  if (!isNull) {
    newData = Object.entries(data).reduce((acc, [key, item]) => {
      if (item !== null) {
        return { ...acc, [key]: item };
      } else return acc;
    }, {});
  } else {
    newData = data;
  }

  //если get - формируем строку из данных
  if (method.toLowerCase() === 'get' || (method.toLowerCase() === 'post' && isQuery)) {
    if (url.endsWith(':id')) {
      newRequest.url = url.replace(':id', newData.id);
    } else {
      const get = Object.keys(newData)
        .map((key) => `${key}=${newData[key]}`)
        .join('&');

      newRequest.url = get ? `${url}?${get}` : url;
    }
  } else {
    newRequest.data = newData;
  }

  // если отправляется файл
  if (isFormData) {
    const form = new FormData();
    Object.entries(newData).forEach(([key, value]) => {
      form.append(key, value);
    });
    newRequest.data = form;
    newRequest.headers = {
      'Content-Type': 'multipart/form-data',
    };
  }
  const tokenAuth = Cookies.get('TOKEN');

  if (tokenAuth) {
    newRequest.headers = {
      ...newRequest.headers,
      Authorization: `Bearer ${tokenAuth}`,
    };
  }

  message.config({
    duration: 2,
  });
  //TODO refactoring
  return await instance(newRequest)
    .then((response) => {
      if (response?.data?.message?.type === 'error') {
        message.error(response?.data?.message?.text);
      } else return allResponse ? response : response.data;
    })
    .catch((error) => {
      console.log({ error });
      if (error.response) {
        if (error && error.response && [500, 400, 403].includes(error.response.status)) {
          //уведомление об ошибке
          console.log(error?.response?.data?.message?.text);
          message.error(error?.response?.data?.message?.text || error.message);
          // console.log('ERROR', error);
          // console.warn('URL', newRequest);
        }
        if (error && error.response && error.response.status === 401) {
          //error?.response?.data?.message?.text && message.error(error?.response?.data?.message?.text);
          if (localStorage.getItem('auth')) {
            localStorage.removeItem('auth');
            //window.location = '/';
          }
        }

        // if (error && error.response && ![200, 500].includes(error.response.status)) {
        //   // localStorage.removeItem('auth');
        //   // Cookies.remove('XSRF-TOKEN');
        // } else {
        //   localStorage.setItem('auth', 'true');
        // }

        return error.response;
      } else {
        error.response = {};
        return error.response;
      }
    });
};

export const getApi = (requests) => {
  return requests(instance);
};

export default makeRequest;
