import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'core/store';
import { ItemParents } from './types';
import { transformServices } from './utils';

type ServiceStateProps = {
  categoryActive: Record<string, any> | null;
  serviceActive: Record<string, any> | null;
  categorySelector: any[]; //запрос 1000 значений для селектора
  category: any[];
  services: any[];
  count: number; //общее количество категорий в базе
  itemsData: Record<string, any>; //дочерние категории и услуги
  search: any[];
  serviceId: number | null; // используется когда для разных случаев нужно запомнить id текущей услуги
};

const initialState: ServiceStateProps = {
  categoryActive: null,
  serviceActive: null,
  category: [],
  categorySelector: [],
  services: [],
  count: 0,
  itemsData: {},
  search: [],
  serviceId: null,
};

const updateItemData = (id: number, dataPrev: Record<string, any>, data?: Record<string, any>[]) => {
  if (data && data?.length) {
    return {
      ...dataPrev,
      [id]: data,
    };
  } else {
    return Object.entries(dataPrev).reduce((acc, [k, v]) => {
      if (+k === id) {
        return acc;
      } else {
        return { ...acc, [k]: v };
      }
    }, {});
  }
};

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    //сброс данных
    //---------------------------------
    resetActiveData: (state: ServiceStateProps) => {
      state.serviceActive = null;
      state.categoryActive = null;
    },
    //---------------------------------
    resetSearchData: (state: ServiceStateProps) => {
      state.search = [];
    },
    //обновление данных объекта дочерний услуг
    //---------------------------------
    updateItemsData: (state: ServiceStateProps, action) => {
      state.itemsData = updateItemData(action.payload.id, state.itemsData, action.payload.data);
    },

    //Поиск всех услуг и категорий
    //---------------------------------
    getSearchServiceRequest: (state: ServiceStateProps, action) => {
      //
    },
    getSearchServiceSuccess: (state: ServiceStateProps, action) => {
      state.search = [...(action.payload?.category?.data || []), ...(action.payload?.services?.data || [])];
    },

    //Получение всех услуг
    //---------------------------------
    getAllServiceRequest: (state: ServiceStateProps, action) => {
      //
    },
    getAllServiceSuccess: (state: ServiceStateProps, action) => {
      state.services = action.payload?.data;
    },

    //Получить услугу по ID
    //---------------------------------
    getServiceIdRequest: (state: ServiceStateProps, action) => {
      //
    },
    getServiceIdSuccess: (state: ServiceStateProps, action) => {
      state.serviceActive = action.payload;
    },

    //Создание услуги
    //---------------------------------
    postCreateServiceRequest: (state: ServiceStateProps, action) => {
      //
    },
    postCreateServiceSuccess: (state: ServiceStateProps, action) => {
      state.serviceActive = action.payload;
    },

    //Копировать услугу
    //---------------------------------
    copyServiceRequest: (state: ServiceStateProps, action) => {
      //
    },
    copyServiceSuccess: (state: ServiceStateProps, action) => {
      state.serviceActive = action.payload;
    },

    //Обновить услугу
    //---------------------------------
    postUpdateServiceRequest: (state: ServiceStateProps, action) => {
      //
    },
    postUpdateServiceSuccess: (state: ServiceStateProps, action) => {
      state.serviceActive = action.payload;
    },

    //Удалить услугу
    //---------------------------------
    deleteServiceRequest: (state: ServiceStateProps, action) => {
      //
    },
    deleteServiceSuccess: (state: ServiceStateProps, action) => {
      //
    },

    /**
     * CATEGORY
     */

    //Получить категории для селектора
    //---------------------------------
    getCategorySelectorRequest: (state: ServiceStateProps) => {
      //
    },
    //---------------------------------
    getCategorySelectorSuccess: (state: ServiceStateProps, action) => {
      //
      state.categorySelector = action.payload?.data;
    },
    //Получить категории
    //---------------------------------
    getAllCategoryRequest: (state: ServiceStateProps, action) => {
      //
    },
    getAllCategorySuccess: (state: ServiceStateProps, action) => {
      state.category = action.payload?.data;
      state.count = action.payload?.count;
    },

    //Создать категорию
    //---------------------------------
    createCategoryRequest: (state: ServiceStateProps, action) => {
      //
    },
    createCategorySuccess: (state: ServiceStateProps, action) => {
      state.categoryActive = action.payload;
    },

    //Получить категорию по ID
    //---------------------------------
    getCategoryIdRequest: (state: ServiceStateProps, action) => {
      //
    },
    getCategoryIdSuccess: (state: ServiceStateProps, action) => {
      state.categoryActive = action.payload;
    },

    //Обновить категорию по ID
    //---------------------------------
    updateCategoryRequest: (state: ServiceStateProps, action) => {
      //
    },
    updateCategorySuccess: (state: ServiceStateProps, action) => {
      state.categoryActive = action.payload;
    },

    //Удалить категорию
    //---------------------------------
    deleteCategoryRequest: (state: ServiceStateProps, action) => {
      //
    },
    deleteCategorySuccess: (state: ServiceStateProps, action) => {
      state.category = state.category?.filter((item) => item.id !== action.payload.id);
      state.count = state.count - 1;
    },

    //Изменит категорию
    //---------------------------------
    changeCategory: (state: ServiceStateProps, action) => {
      state.categoryActive = action.payload;
    },

    // Запомнить или сбросить (передать null) id услуги
    setServiceId: (state: ServiceStateProps, action) => {
      state.serviceId = action.payload;
    },
  },
});
// TODO: поправит export
export const servicesServiceSelector = (s: RootState) => s.service.services;

export const categoryServiceSelector = (s: RootState) => s.service.category;
export const categorySelectorServiceSelector = (s: RootState) => s.service.categorySelector;
export const serviceActiveCategorySelector = (s: RootState) => s.service.serviceActive;
export const countActiveServiceSelector = (s: RootState) => s.service.count;
export const categoryActiveServiceSelector = (s: RootState) => s.service.categoryActive;
export const itemsDataServiceSelector = (s: RootState) => s.service.itemsData;
export const searchDataServiceSelector = (s: RootState) => s.service.search;
export const serviceIdSelector = (s: RootState) => s.service.serviceId;
