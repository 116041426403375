import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { IconArrows } from 'icons';
import { ContentPopUpList, ItemContentPopUpListFilter, TitleFilter } from '../style';
import { useMouseDown } from 'core/hooks';

export type DataSort = {
  id: number;
  label: string;
  value: string | number | string[];
};

type SortingProps = {
  data: DataSort[];
  onChange: (data: DataSort | null, type?: 'asc' | 'desc' | null) => void;
  icon?: any;
  label?: string;
};

export const Sorting: React.FC<SortingProps> = ({ data, onChange, icon = IconArrows, label = 'Сортировка' }) => {
  const listRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);
  const [sortType, setSortType] = useState<'asc' | 'desc' | null>(null);
  const [count, setCount] = useState(1);
  const [active, setActive] = useState<DataSort | null>();

  const [showList, setShowList] = useState(false);

  useMouseDown(() => setShowList(false), listRef, inputRef);

  useEffect(() => {
    switch (count) {
      case 2:
        setSortType('desc');
        break;
      case 3:
        setSortType('asc');
        break;
      default:
        setSortType(null);
        break;
    }
  }, [count]);

  const handleClickSorting = () => {
    setShowList((prev) => !prev);
  };

  const handleClickItem = (data: DataSort) => () => {
    if (count === 3 && active?.id === data?.id) {
      setActive(null);
      onChange(null, sortType);
    } else {
      setActive(data);
      onChange(data, sortType);
    }

    setCount((prev) => prev + 1);

    if (count === 3 && active?.id === data?.id) {
      setCount(1);
    } else if (active && active?.id !== data?.id) setCount(2);

    setShowList(false);
  };

  const title = useMemo(() => (active ? active.label : label), [active, label]);
  const IconFilter = icon;

  return (
    <Content active={showList}>
      <Header ref={inputRef} onClick={handleClickSorting}>
        <WrapperIcon type={sortType}>
          <IconFilter />
        </WrapperIcon>
        <TitleFilter>{title}</TitleFilter>
      </Header>
      <ContentPopUpList ref={listRef} active={showList}>
        {data?.map((item) => (
          <ItemContentPopUpListFilter active={item.id === active?.id} key={item.id} onClick={handleClickItem(item)}>
            {item.label}
          </ItemContentPopUpListFilter>
        ))}
      </ContentPopUpList>
    </Content>
  );
};
const WrapperIcon = styled.div<{ type: 'asc' | 'desc' | null }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  ${({ type }) => {
    switch (type) {
      case 'desc':
        return css`
          svg {
            path {
              &:nth-child(1) {
                display: none;
              }
              &:nth-child(2) {
                display: none;
              }
            }
          }
        `;
      case 'asc':
        return css`
          svg {
            path {
              &:nth-child(3) {
                display: none;
              }
              &:nth-child(4) {
                display: none;
              }
            }
          }
        `;
      default:
        return css`
          svg {
            path {
              display: block;
          }
        `;
    }
  }}
`;
const Header = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const Content = styled.div<{ active: boolean }>`
  padding: 8px 10px;
  width: 100%;
  position: relative;
  border-radius: 4px;
  &:hover {
    background: #f0f3f9;
  }
  ${({ active }) =>
    active
      ? css`
          background: #f0f3f9;
        `
      : null}
`;
