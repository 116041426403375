import React from 'react';
import styled from 'styled-components';
import { IconArrowLeft } from 'icons';
import moment from 'moment';

type GenerateDateMonthPros = {
  month: string;
  monthNum: number;
  onChange: (month: number) => void;
};

export const GenerateDateMonth: React.FC<GenerateDateMonthPros> = ({ month, onChange, monthNum = 0 }) => {
  const handleClickOnChange = (valueMonth: number) => () => {
    onChange(valueMonth);
  };
  return (
    <Content>
      <WrapperIconLeft onClick={handleClickOnChange(monthNum - 1)}>
        <IconArrowLeft />
      </WrapperIconLeft>
      <TextBlock>{moment(month)?.format('MMMM')}</TextBlock>
      <WrapperIconRight onClick={handleClickOnChange(monthNum + 1)}>
        <IconArrowLeft />
      </WrapperIconRight>
    </Content>
  );
};
const WrapperIconRight = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  svg {
    transform: rotate(180deg);
  }
`;
const WrapperIconLeft = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;
const TextBlock = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #6b6f76;
`;
const Content = styled.div`
  padding: 0 10px;
  display: flex;
  align-items: center;
`;
