import React from 'react';
import styled, { css } from 'styled-components';
import moment, { Moment } from 'moment';

const nameDays = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];

type ItemTitleHeadProps = {
  date: Moment;
  index: number;
};

export const ItemTitleHead: React.FC<ItemTitleHeadProps> = ({ date, index }) => {
  const todayDay = moment();

  return (
    <Content>
      <Number active={date.isSame(todayDay, 'day')}>{date.date()}</Number>
      <NameDay>{nameDays[index]}</NameDay>
    </Content>
  );
};
const NameDay = styled.div`
  margin-left: 5px;
`;
const Number = styled.div<{ active: boolean }>`
  height: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ active }) =>
    active
      ? css`
          width: 34px;
          background: #f0f3f9;
        `
      : css`
          width: auto;
          background: var(--color-white);
        `}
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #282a2f;
`;
