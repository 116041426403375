import createRequestRest from 'api/createRequestRest';
import { put } from 'redux-saga/effects';
import { authSlice } from '../authSlice';
import Cookies from 'js-cookie';
import { message } from 'antd';

function* prepareRequest(payload: Record<string, any>) {
  return payload.payload.data;
  yield;
}

function* prepareSuccess(response: any, payload: Record<string, any>) {
  if (response) {
    Cookies.set('TOKEN', response.token);
    payload?.payload?.success?.();
    yield put(authSlice.actions.getLoginSuccess(response));
  } else {
    message.config({
      duration: 4,
    });
    void message.error('Пользователя не существует');
  }
}

function* prepareFailure(res: any) {
  yield;
}

export const getLogin = () => {
  return createRequestRest({
    url: '/api/v1/auth/login',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: authSlice.actions.getLoginRequest,
    method: 'get',
  });
};
