import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { IconArrowDownSmall } from 'icons';
import { useMouseDown } from 'core/hooks';
import { ContentPopUpList } from 'components/style';

export type ItemFilter = {
  id: number;
  value: string | number;
};

type NumberFilerProps = {
  title: string;
  data: ItemFilter[];
  onClick: (d: ItemFilter) => void;
};

export const NumberFiler: React.FC<NumberFilerProps> = ({ title, data = [], onClick }) => {
  const listRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState<ItemFilter>(data[0]);
  const [show, setShow] = useState<boolean>(false);

  useMouseDown(() => setShow(false), listRef, inputRef);

  const handleClickItem = (data: ItemFilter) => () => {
    onClick(data);
    setActive(data);
    setShow(false);
  };
  const handleClick = () => {
    setShow((prev) => !prev);
  };
  return (
    <>
      <Content>
        <Title>{title}</Title>
        <WrapperValue ref={inputRef} onClick={handleClick}>
          <ValueTitle>{active.value}</ValueTitle>
          <WrapperIcon>
            <IconArrowDownSmall />
          </WrapperIcon>
        </WrapperValue>
        <ContentPopUpList ref={listRef} active={show}>
          {data?.map((item) => (
            <Item key={item.id} onClick={handleClickItem(item)}>
              {item.value}
            </Item>
          ))}
        </ContentPopUpList>
      </Content>
    </>
  );
};
const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
`;
const Item = styled.div`
  padding: 7px 16px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black-light);
  cursor: pointer;
  &:hover {
    background: var(--color-grey-light);
  }
`;
const List = styled.div`
  width: 147px;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  border: 0.5px solid #dfe1e4;
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
`;
const Title = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black-light);
  margin-right: 8px;
`;
const WrapperValue = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const ValueTitle = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-blue);
`;
const Content = styled.div`
  padding: 5px;
  position: relative;
  display: flex;
  align-items: center;
`;
