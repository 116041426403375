import createRequestRest from 'api/createRequestRest';
import { put } from 'redux-saga/effects';
import { scheduleSlice } from '../scheduleSlice';

function* prepareRequest(data: any) {
  return data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(scheduleSlice.actions.getScheduleSuccess(response?.data));
}

function* prepareFailure(res: any) {
  yield;
}

export const getDataTimeTable = () => {
  return createRequestRest({
    url: '/api/v1/todolist/timetable/getAll',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: scheduleSlice.actions.getScheduleRequest,
    method: 'get',
  });
};
