import styled from 'styled-components';

export const DefaultButton = styled.button`
  width: 100%;
  background: var(--color-blue);
  padding: 6px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-white);
  border-radius: 4px;
  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
`;

export const IconButton = styled.button`
  background: var(--color-white);
  border: 1px solid var(--color-grey);
  border-radius: 4px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
`;

export const WhiteButton = styled.button`
  width: 100%;
  white-space: nowrap;
  background: var(--color-white);
  border: 1px solid #dfe1e4;
  border-radius: 4px;
  padding: 6px 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black-light);
`;

export const ButtonTime = styled.button`
  border: 1px solid #dfe1e4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 0;
  cursor: pointer;
  &:hover,
  &.time-selected {
    color: var(--color-blue);
    border-color: var(--color-blue);
  }
`;

export const ButtonLink = styled.button`
  background: var(--color-white);
  color: var(--color-blue);
  border: 0;
  outline: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: auto;
  &:hover,
  &:disabled {
    opacity: .85;
  }
`;
