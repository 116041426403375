import React from 'react';
import styled from 'styled-components';
import { ImCopy } from 'react-icons/im';
import { Checkbox } from 'components/checkbox';
import { IconPencil, IconPlus, IconTrash } from 'icons';

type ControlProps = {
  data?: Record<string, any>;
  hiddenPlus?: boolean;
  hiddenTrash?: boolean;
  hiddenCopy?: boolean;
  disabled?: boolean;
  active: boolean;
  setActive: () => void;
  setClickPencil: () => void;
  setClickPlus: () => void;
  setClickTrash: () => void;
  setClickCopy?: () => void;
};

export const Control: React.FC<ControlProps> = ({
  data,
  hiddenPlus = true,
  hiddenTrash = false,
  hiddenCopy = false,
  disabled,
  active,
  setActive,
  setClickPencil,
  setClickPlus,
  setClickTrash,
  setClickCopy,
}) => {
  const title = data?.type === 'service' ? 'Активная услуга' : 'Активная категория';
  return (
    <Content>
      <Item>
        <Checkbox disabled={disabled} active={active} setActive={setActive} title={title} />
      </Item>

      <Item onClick={setClickPencil}>
        <IconPencil />
      </Item>
      {!hiddenPlus && (
        <Item onClick={setClickPlus}>
          <IconPlus />
        </Item>
      )}
      {!hiddenCopy && (
        <ItemCopy onClick={setClickCopy}>
          <ImCopy />
        </ItemCopy>
      )}

      {!hiddenTrash && (
        <Item onClick={setClickTrash}>
          <IconTrash />
        </Item>
      )}
    </Content>
  );
};

const Item = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:first-child) {
    margin-left: 5px;
  }
`;
const ItemCopy = styled(Item)`
  svg {
    height: 14px;
    width: 14px;
  }
`;
const Content = styled.div`
  display: flex;
  align-items: center;
`;
