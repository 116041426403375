import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getMonthDays } from 'core/utils';
import { GenerateDateWeek } from 'features/generateDateWeek';
import { GenerateDateMonth } from 'features/generateDateMonth';
import { TimePicker } from 'components/timePicker';
import { DatePicker } from 'components/datePicker';
import { SelectorStatic } from 'components/selectorStatic';
import { Preloader } from 'components/preloader';
import moment, { Moment } from 'moment';
import { CalendarDays } from './calendarDays';
import { WeekDays } from './weekDays';
import { ScheduleSwitcher } from 'features/schedule/switcher';

type ScheduleCalendarProps = {
  active: number;
  showButtons?: boolean;
  setActive?: (i: number) => void;
}

export const ScheduleCalendar: React.FC<ScheduleCalendarProps> = ({active = 0, showButtons = true, setActive}) => {
  
  const [loading, setLoading] = useState<boolean>(false);
  const [week, setWeek] = useState(0);
  const [months, setMonths] = useState<string[]>(['', '']);
  const [dates, setDates] = useState<Moment[]>([]);
  const [currentMonth, setCurrentMonth] = useState<string | null>();
  const [currentMonthNum, setCurrentMonthNum] = useState<number | null>();

  useEffect(() => {
    setMonths(moment.months());
    setCurrentMonth(moment().format('MMMM'));
    setCurrentMonthNum(moment().month());    
  }, []);

  useEffect(() => {
    const dates = getMonthDays(currentMonthNum || 1);
    const startDate = dates[0];
    const endDate = moment(dates.at(-1));
    setMonths([startDate.toString(), endDate.toString()]);
    setDates(dates.sort((a: Moment | number, b: Moment | number) => moment(a).unix() - moment(b).unix()));
  }, [currentMonthNum]);

  const handleOnChange = (value: number) => {
    setCurrentMonthNum(value);
  };

  return (
    <Content>
      <ControlBlock>
        {showButtons && <ScheduleSwitcher active={active} setActive={setActive}/>}
        {active === 0 &&
          <GenerateDateWeek 
            dateStart={months[0]} 
            dateEnd={months[1]} 
            onChange={handleOnChange} 
            week={week} 
          />}
        {active === 1 && 
          <GenerateDateMonth 
            month={currentMonth || ''}
            monthNum={currentMonthNum || 1}
            onChange={handleOnChange}
          />}
      </ControlBlock>
      <ControlFilterBlock>
        <FilterBlock>
          <p>Филиал</p>
          <SelectorStatic 
            data={[
              {id: '1', value: 'Филиал 1', data: 'Филиал 1'},
              {id: '2', value: 'Филиал 2', data: 'Филиал 2'},
              {id: '3', value: 'Филиал 3', data: 'Филиал 3'},
              {id: '4', value: 'Филиал 4', data: 'Филиал 4'}
            ]} 
            onChange={e => console.log(e)} 
            valueId='1'
          />
        </FilterBlock>
        <FilterBlock>
          <p>Рабочее место</p>
          <SelectorStatic 
            data={[
              {id: '1', value: 'Кабинет 1', data: 'Рабочее место 1'},
              {id: '2', value: 'Кабинет 2', data: 'Рабочее место 2'},
              {id: '3', value: 'Кабинет 3', data: 'Рабочее место 3'},
              {id: '4', value: 'Кабинет 4', data: 'Рабочее место 4'}
            ]} 
            onChange={e => console.log(e)} 
            valueId='1'
          />
        </FilterBlock>
        <FilterBlock>
          <p>Специалист</p>
          <SelectorStatic 
            data={[
              {id: '1', value: 'Иванов А.И.', data: 'Иванов А.И.'},
              {id: '2', value: 'Петров Р.М.', data: 'Петров Р.М.'},
            ]} 
            onChange={e => console.log(e)} 
            valueId='1'
          />
        </FilterBlock>
        <FilterBlock>
          <p>Дата</p>
          <DatePicker
            title={''}
            value={'01.02.2024'}
            placeholder={'дд.мм.гггг'}
            height={32}
            disabled={false}
            showSuffix={false}
            valid={true}
            onChange={(e) => console.log(e)}
          />
        </FilterBlock>
        <FilterBlock>
          <p>Рабочее время</p>
          <div>
              <TimePicker
                title={'Начало'}
                value={'10:00'}
                placeholder={'чч:мм'}
                height={32}
                disabled={false}
                showSuffix={false}
                valid={true}
                onChange={(e) => console.log(e)}
              />
            <div>&ndash;</div>
            <TimePicker
              title={'Окончание'}
              value={'19:00'}
              placeholder={'чч:мм'}
              height={32}
              disabled={false}
              showSuffix={false}
              valid={true}
              onChange={(e) => console.log(e)}
            />
          </div>
        </FilterBlock>
        <FilterBlock>
          <p>Неабочее время</p>
          <div>
              <TimePicker
                title={'Начало'}
                value={'14:00'}
                placeholder={'чч:мм'}
                height={32}
                disabled={false}
                showSuffix={false}
                valid={true}
                onChange={(e) => console.log(e)}
              />
            <div>&ndash;</div>
            <TimePicker
              title={'Окончание'}
              value={'15:00'}
              placeholder={'чч:мм'}
              height={32}
              disabled={false}
              showSuffix={false}
              valid={true}
              onChange={(e) => console.log(e)}
            />
          </div>
        </FilterBlock>
      </ControlFilterBlock>
      <CalendarBlock>
        <WeekDays />
        <CalendarDays dates={dates} />
      </CalendarBlock>
      {loading && <Preloader loading={loading} />}
    </Content>
  );
};


const Content = styled.div`
  padding: 24px 20px;
  background: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 12px;
  border-radius: 8px;
  width: 100%;
`;
const ControlBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  & > *:last-child {
    justify-self: flex-end;
  }
  width: 100%;
`;
const CalendarBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap:5px;
  padding: 0;
  width: 100%;
  min-width: 800px;
  overflow-x: auto;
  overflow-y: unset;
`;
const ControlFilterBlock = styled(ControlBlock)`
  justify-content: flex-start;
  gap: 15px;
`;
const FilterBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap:8px;
  & > * {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    font-size: 11px;
    color: #6B6F76;
  }
`;