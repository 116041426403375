import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '../../../../components/checkbox';
import { Input } from '../../../../components/input';
import { Textarea } from '../../../../components/textarea';
import { DefaultButton, WhiteButton } from '../../../../components/buttons';

const noop = () => undefined;
export const DeliveryContacts: React.FC = () => {
  return (
    <Content>
      <Row>
        <Title>Поле</Title>
        <Title>Обязательная запись</Title>
      </Row>
      <Row>
        <BlockDelivery>
          <Title>Кнопка выбора доставки</Title>
          <Checkbox active={true} setActive={noop} />
        </BlockDelivery>
      </Row>
      <Row>
        <WrapperInput>
          <Input placeholder='ФИО' />
        </WrapperInput>
        <Checkbox active={true} setActive={noop} />
      </Row>
      <Row>
        <WrapperInput>
          <Input placeholder='Телефон' />
        </WrapperInput>
        <Checkbox active={true} setActive={noop} />
      </Row>
      <Row>
        <WrapperInput>
          <Input placeholder='Адрес доставки' />
        </WrapperInput>
        <Checkbox active={true} setActive={noop} />
      </Row>
      <Row>
        <WrapperInput>
          <Input placeholder='Дата доставки' />
        </WrapperInput>
        <Checkbox active={true} setActive={noop} />
      </Row>
      <Row>
        <WrapperInput>
          <Input placeholder='Дополнительная информация' />
        </WrapperInput>
        <Checkbox active={true} setActive={noop} />
      </Row>
      <Row>
        <div>
          <TitleDelivery>Информация о доставке:</TitleDelivery>
          <Textarea />
        </div>
        <Checkbox active={true} setActive={noop} />
      </Row>
      <WrapperButtons>
        <DefaultButton>Сохранить</DefaultButton>
        <WhiteButton>Отменить</WhiteButton>
      </WrapperButtons>
      <Dev><h4>В разработке</h4></Dev>
    </Content>
  );
};
const WrapperButtons = styled.div`
  margin-top: 100px;
  display: grid;
  grid-template-columns: 150px 150px;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
`;

const TitleDelivery = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: var(--color-black);
  margin-bottom: 13px;
`;
const WrapperInput = styled.div`
  width: 320px;
`;
const BlockDelivery = styled.div`
  display: grid;
  grid-template-columns: 170px 50px;
  grid-template-rows: 1fr;
  grid-row-gap: 12px;
`;
const Title = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: var(--color-black);
`;
const Content = styled.div`
  padding: 40px 60px;
  position: relative;
  pointer-events: none;
  opacity: 0.65;
`;

const Row = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 100px;
  grid-column-gap: 150px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Dev = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > h4 {
    text-align: center;
    opacity: 1;
    color: #461684;
    padding: 2em;
    background-color: rgba(255,255,255,0.8);
  }
`;