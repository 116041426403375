import createRequestRest from 'api/createRequestRest';
import { put } from 'redux-saga/effects';
import { serviceSlice } from '../serviceSlice';

function* prepareRequest(payload: any) {
  return payload?.payload?.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(serviceSlice.actions.createCategorySuccess(response));
  if (response) {
    payload?.payload?.success?.(response);
  }
}

function* prepareFailure(res: any) {
  yield;
}

export const createService = () => {
  return createRequestRest({
    url: '/api/v1/service/category/create',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: serviceSlice.actions.createCategoryRequest,
    method: 'post',
    isFormData: true,
  });
};
