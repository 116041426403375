import { ItemSelectProsTree } from './index';

export const transformDataSelectorService = (data: Record<string, any>[]): ItemSelectProsTree[] => {
  return data?.map((item) => ({
    id: item?.id,
    category_id: item?.category_id,
    is_active: item?.is_active,
    name: item.title,
    active: false,
    items:
      item?.categories_recurse && Array.isArray(item.categories_recurse)
        ? transformDataSelectorService(item?.categories_recurse)
        : [],
  }));
};
export const activeItemSelectorTree = (data: ItemSelectProsTree[], id: number | symbol): ItemSelectProsTree[] => {
  return data.map((item) => {
    if (item.id === id) {
      return { ...item, active: true, items: item?.items?.length ? activeItemSelectorTree(item.items, id) : [] };
    }
    return { ...item, active: false, items: item?.items?.length ? activeItemSelectorTree(item.items, id) : [] };
  });
};

export const activeClearItemSelectorTree = (data?: ItemSelectProsTree[]): ItemSelectProsTree[] => {
  return data
    ? data?.map((item) => {
        return { ...item, active: false, items: item?.items?.length ? activeClearItemSelectorTree(item?.items) : [] };
      })
    : [];
};

export const getActiveDataSelector = (data?: ItemSelectProsTree[], id?: number | symbol) => {
  let result: ItemSelectProsTree | null = null;

  const searchItemSelector = (data: ItemSelectProsTree[], id: number | symbol) => {
    data.forEach((item) => {
      if (item.id === id) {
        result = item;
      } else {
        if (item?.items && !!item?.items?.length) {
          searchItemSelector(item.items, id);
        }
      }
    });
  };

  if (data && id) {
    searchItemSelector(data, id);
  }

  return result;
};
