import createRequestRest from 'api/createRequestRest';
import { kanbanSlice } from '../kanbanSlice';

function* prepareRequest(payload: any) {
  return payload?.payload?.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  // yield put(kanbanSlice.actions.updateColumnTodoListSuccess(response));
  payload?.payload?.success?.();
  yield;
}

function* prepareFailure(res: any, payload: any) {
  payload?.payload?.failure?.();
  yield;
}

export const updateColumn = () => {
  return createRequestRest({
    url: '/api/v1/todolist/column/update',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: kanbanSlice.actions.updateColumnTodoListRequest,
    method: 'post',
  });
};
