import React, { useCallback, useState } from 'react';
import debounce from 'lodash/debounce';
import { requestsSearch } from 'api/search';
import { transformSearchClients } from './helpers';
import { ItemDataProps } from 'features/searchClientsSelector';
import config from 'api/config';

export const useSearchClients = ({
  setLoading,
  setSearch,
  handledValidClient,
}: {
  setLoading: (value: ((prevState: boolean) => boolean) | boolean) => void;
  setSearch: (value: ((prevState: string) => string) | string) => void;
  handledValidClient: (value: boolean) => void;
}) => {
  const [requestData, setRequestData] = useState<ItemDataProps[]>([]);
  const [itemClient, setItemClient] = useState<Record<string, any> | null>(null);

  // const dispatch = useAppDispatch();

  const showValue = (search: string) => {
    requestsSearch.get_all_client(search).then((res: any) => {
      if (res?.data) {
        setLoading(false);
        if (res?.data?.data) {
          setRequestData(transformSearchClients(res.data.data));
        }
      }
    });
  };

  const handledDebounce = useCallback(debounce(showValue, config.delaySearch), []);

  const handledOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(!!e.target.value);
    setSearch(e.target.value);
    if (e.target.value === '') {
      setRequestData([]);
    } else {
      handledDebounce(e.target.value);
    }
    setItemClient(null);
  };

  const handleClickItem = (data: Record<string, any>) => {
    setItemClient(data);
    setSearch(data?.name);
    handledValidClient(data?.id);
  };

  const clearItemClient = () => {
    setItemClient(null);
    setSearch('');
    handledValidClient(false);
  };

  return {
    requestData,
    handledOnChangeSearch,
    handleClickItem,
    activeClient: itemClient,
    setActiveClient: setItemClient,
    clearClient: clearItemClient,
  };
};
