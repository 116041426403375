import createRequestRest from 'api/createRequestRest';
import { put } from 'redux-saga/effects';

import { transformBranch } from '../utils';
import { branchSlice } from '../branchSlice';

function* prepareRequest(payload: any) {
  return payload?.payload?.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  let data: any = [];  
  if (response?.data) {
    payload?.payload?.success?.(response?.data);
    data = transformBranch(response?.data);
  }
  yield put(branchSlice.actions.getAllBranchSuccess({ data, count: response?.count }));
}

function* prepareFailure(res: any) {
  yield;
}

export const getBranchAll = () => {
  return createRequestRest({
    url: '/api/v1/branch/getAll',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: branchSlice.actions.getAllBranchRequest,
    method: 'get',
  });
};
