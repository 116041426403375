import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'core/store';

type AuthStateProps = {
  token: string;
  // tokenCode: string;
  user?: Record<string, any> | null;
  space: any;
};

const initialState: AuthStateProps = {
  token: '',
  // tokenCode: '',
  user: null,
  space: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    //сброс данных
    //---------------------------------
    resetTokenCode: (state: AuthStateProps) => {
      // state.tokenCode = '';
    },
    //---------------------------------
    resetUser: (state: AuthStateProps) => {
      state.user = null;
    },
    //---------------------------------
    resetSpace: (state: AuthStateProps) => {
      state.space = null;
    },

    //Получение token
    //---------------------------------
    getTokenRequest: (state: AuthStateProps, action) => {
      //
    },
    getTokenSuccess: (state: AuthStateProps, action) => {
      state.token = '';
    },
    //Регистрация
    //---------------------------------
    getRegisterRequest: (state: AuthStateProps, action) => {
      //
    },
    getRegisterSuccess: (state: AuthStateProps, action) => {
      // state.tokenCode = action.payload.data.token;
    },
    //Авторизация
    //---------------------------------
    getLoginRequest: (state: AuthStateProps, action) => {
      //
    },
    getLoginSuccess: (state: AuthStateProps, action) => {
      state.user = action.payload.data.user;
    },
    //Logout
    //---------------------------------
    getLogoutRequest: (state: AuthStateProps, action) => {
      //
    },
    getLogoutSuccess: (state: AuthStateProps, action) => {
      state.user = {};
    },
    //Reset
    //---------------------------------
    getResetRequest: (state: AuthStateProps, action) => {
      //
    },
    getResetSuccess: (state: AuthStateProps, action) => {
      // state.tokenCode = action.payload.data.token;
    },
    //Edit
    //---------------------------------
    getEditRequest: (state: AuthStateProps, action) => {
      //
    },
    getEditSuccess: (state: AuthStateProps, action) => {
      // state.tokenCode = action.payload.data.token;
    },
    //Подтверждение
    //---------------------------------
    getConfirmRequest: (state: AuthStateProps, action) => {
      //
    },
    getConfirmSuccess: (state: AuthStateProps, action) => {
      state.user = action.payload.data.user;
    },
    //Подтверждение
    //---------------------------------
    getUserRequest: (state: AuthStateProps) => {
      //
    },
    getUserSuccess: (state: AuthStateProps, action) => {
      state.user = action.payload.data.user;
    },

    //ПРОСТРАНСТВА
    //Получение пространств
    //---------------------------------
    getSpaceAllRequest: (state: AuthStateProps) => {
      //
    },
    getSpaceAllSuccess: (state: AuthStateProps, action) => {
      state.space = action.payload;
    },
    //Выбор пространства
    //---------------------------------
    postSpaceRequest: (state: AuthStateProps, action) => {
      //
    },
    postSpaceSuccess: (state: AuthStateProps, action) => {
      // state.space = action.payload;
    },
  },
});

export const tokenAuthSelector = (s: RootState) => s.auth.token;
// export const tokenCodeAuthSelector = (s: RootState) => s.auth.tokenCode;
export const userAuthSelector = (s: RootState) => s.auth.user;
export const spaseAuthSelector = (s: RootState) => s.auth.space;
