import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
//import { PlusInput } from 'features/plusInput';
import { SearchSelectorDefault } from 'features/searchSelectorDefault';
import { useSearch } from 'pages/kanban/hooks/useSearch';
import { requestsSearch } from 'api/search';
//import { IconClose } from 'icons';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { kanbanSelector } from 'pages/kanban/kanbanSlice';
import { serviceIdSelector } from 'pages/service/serviceSlice';
import { specialistSlice } from 'pages/specialist/specialistSlice';
import { entrySlice } from 'features/entry/entrySlice';

type SpecialistItemProps = {
  getActiveData?: (data?: Record<string, any> | null) => void;
};

export const SpecialistItem: React.FC<SpecialistItemProps> = ({ getActiveData }) => {
  const dispatch = useAppDispatch();
  const activeCard = useAppSelector(kanbanSelector.activeCard);
  const serviceId = useAppSelector(serviceIdSelector);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchSpecialistValue, setSearchSpecialistValue] = useState('');

  const { handledOnChangeSearch, handleClickItem, activeItem, clearItem, requestData, setActiveItem } = useSearch({
    setLoading: setLoading,
    setSearch: setSearchSpecialistValue,
    request: (search?: string, size?: number) => 
      requestsSearch
        .get_specialist({
          title: search || null,
          size: size || null,
          service_id: serviceId || activeCard?.service?.service_id || null,
        })
        .then((res: any) => {
          if (res?.data) {
            setLoading(false);
            if (res?.data?.data) {              
              return res?.data?.data.map((item: Record<string, any>) => ({
                id: item.id,
                name: [item?.name, item?.surname, item.patronymic].filter(Boolean).join(' '),
              }));
            }
          }
        })
  });

  useEffect(() => {
    getActiveData?.(activeItem);
    dispatch(specialistSlice.actions.setSpecialistId(activeItem?.id));
  }, [activeItem]);

  useEffect(() => {
    if (activeCard) {
      const specialist = activeCard?.specialist;      
      specialist &&
        setActiveItem({
          id: specialist?.id || specialist?.specialist_id,
          name: [specialist?.name || '', specialist?.surname || '', specialist.patronymic || '']
            .filter(Boolean)
            .join(' '),
        });
    }
  }, [activeCard]);

  return (
    <Content>
      <SearchSelectorDefault
        onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
          clearItem();
          dispatch(specialistSlice.actions.setSpecialistId(null));
          setLoading(true);
          handledOnChangeSearch(e);
        }}
        onBlur={() => setLoading(false)}
        value={searchSpecialistValue || activeItem?.name || ''}
        loading={loading}
        valid={searchSpecialistValue || activeItem?.name ? true : false}
        onChange={(e) => {
          handledOnChangeSearch(e);
          if (e.target.value === '') {
            dispatch(specialistSlice.actions.setSpecialistId(null));
            dispatch(entrySlice.actions.resetEntry());
          }
        }}
        data={requestData}
        handleClickItem={(item) => {
          handleClickItem(item);
          dispatch(specialistSlice.actions.setSpecialistId(item?.id));
        }}
        isClear
        onClear={() => {
          clearItem();
          dispatch(specialistSlice.actions.setSpecialistId(null));
          dispatch(entrySlice.actions.resetEntry());
        }}
      />
      {/*activeItem ? (
        <WrapperTitle>
          <Title>{activeItem.name}</Title>
          <WrapperIcon onClick={clearItem}>
            <IconClose />
          </WrapperIcon>
        </WrapperTitle>
      ) : (
        <>
          <PlusInput
            component={
              <SearchSelectorDefault
                onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLoading(true);
                  handledOnChangeSearch(e);
                }}
                onBlur={() => setLoading(false)}
                value={searchSpecialistValue || ''}
                loading={loading}
                onChange={handledOnChangeSearch}
                data={requestData}
                handleClickItem={handleClickItem}
                isClear
                onClear={clearItem}
              />
            }
          />
        </>
      )*/}
    </Content>
  );
};

const Content = styled.div``;
/*const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black-light);
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
  width: 220px;
`;
const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
`;
const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  cursor: pointer;
  svg {
    path {
      stroke: #d23333;
    }
  }
`;*/
