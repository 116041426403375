import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BlockTabsContent, TitleBlock } from 'components/style';
import { Input } from 'components/input';
import { ItemSelectPros, SelectorStatic } from 'components/selectorStatic';
import { Checkbox } from 'components/checkbox';
import { InputMack } from 'components/inputMask';
import { isEmpty, isEmail, isPhone} from 'core/utils';
import { useTabsContext } from './context';
import { DatePicker } from '../../../../components/datePicker';

const genderIds: Record<string, string | undefined> = {
  male: '0',
  female: '1',
  none: undefined,
};

const gardenData: ItemSelectPros[] = [
  { id: '0', value: 'Mужской', data: 'male' },
  { id: '1', value: 'Женский', data: 'female' },
];

const initValidData: Record<string, boolean> = {
  name: true,
  phone: true,
  email: true,
};

type GeneralInformationProps = {
  setChanged: (isChanged: boolean) => void;
};

export const GeneralInformation: React.FC<GeneralInformationProps> = ({ setChanged }) => {
  const [search, setSearch] = useState<string>();

  const [validData, setValidData] = useState(initValidData);
  const { state: stateTabs, setState: setStateTabs } = useTabsContext();

  const data = useMemo(() => stateTabs?.general?.data, [stateTabs?.general?.data]);  

  useEffect(() => {
    setStateTabs?.((prev) => ({
      ...prev,
      general: {
        ...prev?.general,
        onIsValid: () => {
          /*const newValid = Object.entries(validData).reduce(
            (acc, [key, _]) => ({ ...acc, [key]: !!prev?.general?.data?.[key] }),
            {},
          );*/
          if (stateTabs?.general?.data) {
            setValidData(validData);
            return Object.values(validData).filter(value => !value).length ? false : true;
          } else {
            setValidData({
              ...validData,
              name: false,
              phone: false,
            });
            return false;
          }          
          /*return !Object.entries(newValid)
            .map(([_, item]) => item)
            .includes(false);*/
        },
      },
    }));
  }, [validData, setValidData]);

  const handleOnChangeDate = (name: string) => (value?: string) => {
    setStateTabs?.((prev) => {
      const nextData = {
        ...prev,
        general: { ...prev?.general, data: { ...prev?.general?.data, [name]: value } },
      };
      return nextData;
    });

    if (Object.keys(validData).includes(name)) {
      setValidData({ ...validData, [name]: true });
    }

    setChanged(true);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateTabs?.((prev) => {
      const nextData = {
        ...prev,
        general: { ...prev?.general, data: { ...prev?.general?.data, [e.target.name]: e.target.value } },
      };
      return nextData;
    });

    if (Object.keys(validData).includes(e.target.name)) {
      setValidData({ ...validData, [e.target.name]: true });
    }

    setChanged(true);
  };

  const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateTabs?.((prev) => ({
      ...prev,
      general: { ...prev?.general, data: { ...prev?.general?.data, [e.target.name]: e.target.value } },
    }));
    setValidData({ ...validData, name: isEmpty(e.target.value) });
    setChanged(true);
  };

  const handleOnChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateTabs?.((prev) => ({
      ...prev,
      general: { ...prev?.general, data: { ...prev?.general?.data, [e.target.name]: e.target.value } },
    }));
    setValidData({ ...validData, email: isEmail(e.target.value) });
    setChanged(true);
  };

  const handleOnChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateTabs?.((prev) => ({
      ...prev,
      general: { ...prev?.general, data: { ...prev?.general?.data, [e.target.name]: e.target.value } },
    }));
    setValidData({ ...validData, phone: isPhone(e.target.value) });
    setChanged(true);
  };  
  return (
    <>
      <Content>
        <BlocContentModel>
          <WrapperBlock>
            <Wrapper>
              <TitleBlock>Данные клиента</TitleBlock>
              <WrapperMin>
                <WrapperInput>
                  <Input placeholder='Фамилия' value={data?.surname} name={'surname'} onChange={handleOnChange} />
                </WrapperInput>
                <WrapperInput>
                  <WrapperBlockMin>
                    <Input
                      valid={validData.name}
                      placeholder='Имя'
                      value={data?.name}
                      name={'name'}
                      onChange={handleOnChangeName}
                      required={true}
                      invalidText={!data?.name?.length ? 'Имя не может быть пустым' : ''}
                    />
                    <Input
                      placeholder='Отчество'
                      value={data?.patronymic}
                      name={'patronymic'}
                      onChange={handleOnChange}
                    />
                  </WrapperBlockMin>
                </WrapperInput>
                <WrapperInput>
                  <WrapperBlockMin>
                    <DatePicker
                      title='Дата рождения'
                      value={data?.birthday_at}
                      onChange={handleOnChangeDate('birthday_at')}
                    />
                    {/*<InputMack*/}
                    {/*  mask={'99.99.9999'}*/}
                    {/*  valid={validData.birthday_at}*/}
                    {/*  placeholder='Дата рождения'*/}
                    {/*  value={data?.birthday_at}*/}
                    {/*  name={'birthday_at'}*/}
                    {/*  onChange={handleOnChange}*/}
                    {/*/>*/}
                    <SelectorStatic
                      valueId={genderIds[data?.gender || 'none']}
                      placeholder='Пол'
                      data={gardenData}
                      onChange={(e) => {
                        setValidData({ ...validData, gender: true });
                        setStateTabs?.((prev) => ({
                          ...prev,
                          general: { ...prev?.general, data: { ...prev?.general?.data, gender: e.data } },
                        }));
                        setChanged(true);
                      }}
                    />
                  </WrapperBlockMin>
                </WrapperInput>
                <Input placeholder='Профессия' value={data?.profession} name={'profession'} onChange={handleOnChange} />
              </WrapperMin>
            </Wrapper>
            <Wrapper>
              <TitleBlock>Паспорт</TitleBlock>
              <WrapperMin>
                <WrapperInput>
                  <WrapperBlockMin>
                    <InputMack
                      mask={'9999'}
                      placeholder='Серия'
                      value={data?.series}
                      name={'series'}
                      onChange={handleOnChange}
                    />
                    <InputMack
                      mask={'999999'}
                      placeholder='Номер'
                      value={data?.number}
                      name={'number'}
                      onChange={handleOnChange}
                    />
                  </WrapperBlockMin>
                </WrapperInput>
                <WrapperInput>
                  <WrapperBlockMin>
                    <InputMack
                      mask='999-999'
                      placeholder='Код подразделения'
                      value={data?.code}
                      name={'code'}
                      onChange={handleOnChange}
                    />
                    <DatePicker title='Дата выдачи' value={data?.begin_at} onChange={handleOnChangeDate('begin_at')} />
                    {/*<InputMack*/}
                    {/*  mask={'99.99.9999'}*/}
                    {/*  placeholder='Дата выдачи'*/}
                    {/*  value={data?.begin_at}*/}
                    {/*  name={'begin_at'}*/}
                    {/*  onChange={handleOnChange}*/}
                    {/*/>*/}
                  </WrapperBlockMin>
                </WrapperInput>
                <Input placeholder='Кем выдан' value={data?.issued_by} name={'issued_by'} onChange={handleOnChange} />
              </WrapperMin>
            </Wrapper>
          </WrapperBlock>
        </BlocContentModel>
        <BlockTabsContent>
          <WrapperBlock>
            <Wrapper>
              <TitleBlock>Контакты</TitleBlock>
              <WrapperMin>
                <WrapperInput>
                  <InputMack
                    mask='+7 (999) 999-99-99'
                    valid={validData.phone}
                    placeholder='Телефон'
                    value={data?.phone}
                    name={'phone'}
                    required={true}
                    invalidText='Неверный формат телефона'
                    onChange={handleOnChangePhone}
                  />
                </WrapperInput>
                <WrapperInput>
                  <Input 
                    placeholder='E-mail' 
                    valid={validData.email} 
                    value={data?.email} 
                    name={'email'} 
                    invalidText={data?.email?.length ? 'Неверный формат e-mail' : ''}
                    onChange={handleOnChangeEmail} 
                  />
                </WrapperInput>
                <WrapperInput>
                  <Input
                    placeholder='Адрес регистрации'
                    value={data?.address_register}
                    name={'address_register'}
                    onChange={handleOnChange}
                  />
                </WrapperInput>
                <WrapperInput>
                  <Input
                    placeholder='Адрес фактический'
                    value={data?.address_repeat ? data?.address_register : data?.address_fact}
                    name={'address_fact'}
                    onChange={handleOnChange}
                  />
                </WrapperInput>
                <Checkbox
                  title='Адреса совпадают'
                  active={data?.address_repeat || (data?.address_fact && data?.address_fact === data?.address_register)}
                  setActive={() => {
                    setStateTabs?.((prev) => ({
                      ...prev,
                      general: {
                        ...prev?.general,
                        data: { ...prev?.general?.data, address_repeat: !data?.address_repeat },
                      },
                    }));
                    setChanged(true);
                  }}
                />
              </WrapperMin>
            </Wrapper>
            <Wrapper>
              <TitleBlock>Прочее</TitleBlock>
              <WrapperMin>
                <WrapperInput>
                  <Input
                    placeholder='Канал обращения'
                    value={data?.channel}
                    name={'channel'}
                    onChange={handleOnChange}
                  />
                </WrapperInput>
                <WrapperInput>
                  <Input
                    placeholder='Способ оплаты'
                    value={data?.terminal}
                    name={'terminal'}
                    onChange={handleOnChange}
                  />
                </WrapperInput>
                <WrapperInput>
                  <Input
                    placeholder='Причина обращения'
                    value={data?.reason}
                    name={'reason'}
                    onChange={handleOnChange}
                  />
                </WrapperInput>
                <WrapperInput>
                  <Input placeholder='Комментарий' value={data?.comment} name={'comment'} onChange={handleOnChange} />
                </WrapperInput>
                <Checkbox
                  title='Отключить от всех уведомлений'
                  active={!!data?.notification_disable}
                  setActive={() => {
                    setStateTabs?.((prev) => ({
                      ...prev,
                      general: {
                        ...prev?.general,
                        data: {
                          ...prev?.general?.data,
                          notification_disable: !prev?.general?.data?.notification_disable,
                        },
                      },
                    }));
                    setChanged(true);
                  }}
                />
              </WrapperMin>
            </Wrapper>
          </WrapperBlock>
        </BlockTabsContent>
        <BlockTabsContent>
          <WrapperBlock>
            <Wrapper>
              <TitleBlock>Транспорт</TitleBlock>
              <WrapperMin>
                <WrapperInput>
                  <Input
                    placeholder='Права'
                    value={data?.driver_license}
                    name={'driver_license'}
                    onChange={handleOnChange}
                  />
                </WrapperInput>
                <WrapperInput>
                  <Input placeholder='ПТС' value={data?.car_pts} name={'car_pts'} onChange={handleOnChange} />
                </WrapperInput>
                <WrapperInput>
                  <Input
                    placeholder='Страховка'
                    value={data?.car_insurance}
                    name={'car_insurance'}
                    onChange={handleOnChange}
                  />
                </WrapperInput>

                <Input
                  placeholder='Номер машины'
                  value={data?.car_number}
                  name={'car_number'}
                  onChange={handleOnChange}
                />
              </WrapperMin>
            </Wrapper>
            <Wrapper>
              <TitleBlock>Страхование</TitleBlock>
              <WrapperMin>
                <WrapperInput>
                  <InputMack
                    mask={'99999999999'}
                    placeholder='СНИЛС'
                    value={data?.snils}
                    name={'snils'}
                    onChange={handleOnChange}
                  />
                </WrapperInput>
                <WrapperInput>
                  <InputMack
                    mask={'9999999999'}
                    placeholder='ИНН'
                    value={data?.inn}
                    name={'inn'}
                    onChange={handleOnChange}
                  />
                </WrapperInput>
                <WrapperInput>
                  <Input
                    placeholder='Полис ОМС'
                    value={data?.policy_oms}
                    name={'policy_oms'}
                    onChange={handleOnChange}
                  />
                </WrapperInput>
                <Input
                  placeholder='Полис ДМС и название страховой'
                  value={data?.policy_dms}
                  name={'policy_dms'}
                  onChange={handleOnChange}
                />
              </WrapperMin>
            </Wrapper>
          </WrapperBlock>
        </BlockTabsContent>
      </Content>
    </>
  );
};
const WrapperInput = styled.div`
  margin-bottom: 16px;
`;
const Content = styled.div``;
const Wrapper = styled.div`
  min-width: 450px;
`;
const WrapperMin = styled.div`
  margin-top: 10px;
`;
const WrapperBlockMin = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
`;
const WrapperBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  padding: 20px;
`;
const BlocContentModel = styled.div`
  background: #ffffff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 8px;
  margin-bottom: 8px;
`;
