import React from 'react';
import styled, {css} from 'styled-components';

type DayItemProps = {
  item: Record<string, any>;
  itemsCount: number;
  prev?: boolean;
  onRemove?: (item: Record<string, any>) => void;
}

export const DayItem: React.FC<DayItemProps> = ({item, itemsCount, prev, onRemove}) => {

  const handleItemRemove = () => onRemove?.(item);

  return (
    <Content itemsCount={itemsCount}>
      <ItemName>{item.name}</ItemName>
      <p>{item.grafik.from} - {item.grafik.to}</p>
      {item?.timeout?.map((b: Record<string, any>, i: number) => <p key={i}>({b.from}-{b.to})</p>)}
      {!prev && <ItemDelete title="Удалить" onClick={handleItemRemove}>&#x2715;</ItemDelete>}
    </Content>
);
};

const Content = styled.div<{itemsCount: number}>`
  position: relative;
  border-radius: 3px;
  background-color: #E6DAF9;
  color: #282A2F;
  width: 100%;
  padding: 5px 7px;
  line-height: 1.5;
  & p {margin: 0;padding:0;}
  ${({ itemsCount }) => {
    if (itemsCount === 1) {
      return css`
        background-color: #E6DAF9;
        height: 100%;
      `;
    } else if (itemsCount > 1) {
      return css`
        background-color: #EFF1F4;
      `;
    }
  }};
`;
const ItemName = styled.p`
  margin-bottom: 5px!important;
`;
const ItemDelete = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 9;
  color: var(--color-red);
  font-size: 12px;
  &:hover {
    opacity: .75;
    cursor: pointer;
  }
`;
