import React, { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { IconRub } from 'icons';

type InputCurrencyProps = InputHTMLAttributes<HTMLInputElement> & {
  icon?: any;
  valid?: boolean;
};

export const InputCurrency: React.FC<InputCurrencyProps> = (props) => {
  const Icon = props?.icon && props.icon;
  const { valid = true } = props;
  return (
    <WrapperInput>
      {!!props?.icon && (
        <WrapperIcon>
          <Icon />
        </WrapperIcon>
      )}
      <Content icon={!!props.icon} valid={!!valid} required={!!props.required || false} {...props} />
      {props.placeholder && (
        <Placeholder valid={!!valid} icon={!!props.icon}>
          {props.placeholder}
        </Placeholder>
      )}
      <CurrentIcon>
        <IconRub />
      </CurrentIcon>
    </WrapperInput>
  );
};
const CurrentIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
`;
const Placeholder = styled.span<{ icon: boolean; valid: boolean }>`
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translateY(50%);
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  transition: all 0.33s ease;

  ${({ valid }) => {
    if (valid) {
      return css`
        color: var(--color-black-grey);
      `;
    } else {
      return css`
        color: var(--color-red);
      `;
    }
  }};

  ${({ icon }) => {
    if (icon) {
      return css`
        left: 40px;
      `;
    } else {
      return css`
        left: 16px;
      `;
    }
  }}
`;
const WrapperIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 0;
  transform: translateY(70%);
  display: flex;
  align-items: center;
  justify-content: center;
`;
const WrapperInput = styled.div`
  width: 100%;
  position: relative;
`;
const Content = styled.input<{ icon: boolean; valid: boolean }>`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black);
  height: 40px;
  border: 1px solid #dfe1e4;
  border-radius: 8px;
  width: 100%;

  &::placeholder {
    color: #ffffff00;
  }

  &:focus + ${Placeholder} {
    transform: translateY(0);
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    top: -6px;
    background: var(--color-white);
    padding: 0 5px;
  }
  &:valid + ${Placeholder} {
    transform: translateY(0);
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    top: -6px;
    background: var(--color-white);
    padding: 0 5px;
  }
  ${({ valid }) => {
    if (valid) {
      return css`
        border: 1px solid #dfe1e4;
      `;
    } else {
      return css`
        border: 1px solid var(--color-red);
      `;
    }
  }};

  ${({ icon }) => {
    if (icon) {
      return css`
        padding: 6px 12px 6px 40px;
      `;
    } else {
      return css`
        padding: 6px 12px;
      `;
    }
  }};
  padding-right: 18px;
`;
