import React, { MutableRefObject, useRef } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { useEffect } from 'react';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useMouseDown = (
  onCancel: () => void,
  contentRef?: MutableRefObject<HTMLElement | null>,
  inputRef: MutableRefObject<HTMLElement | null> | string = 'default',
) => {
  useEffect(() => {
    const listener = (event: Record<string, any>) => {
      if (
        !contentRef?.current ||
        contentRef?.current?.contains(event.target) ||
        (typeof inputRef !== 'string' && (!inputRef?.current || inputRef?.current?.contains(event.target)))
      ) {
        return;
      }
      onCancel();
    };

    window.addEventListener('mousedown', listener);
    window.addEventListener('touchstart', listener);

    return () => {
      window.removeEventListener('mousedown', listener);
      window.removeEventListener('touchstart', listener);
    };
  }, [contentRef, inputRef, onCancel]);
};
