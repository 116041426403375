import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'components/input';
import { Textarea } from 'components/textarea';
import { AddPhoto } from 'features/addPhoto';
import { CheckboxService } from 'components/checkboxService';
import { DefaultButton, IconButton, WhiteButton } from 'components/buttons';
import { IconTrash } from 'icons';
//import { useTabsContext } from '../context';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { recordPrioritySlice, telegramSettingsServiceSelector } from 'pages/recordingPriority/recordingPrioritySlice';
import { Preloader } from 'components/preloader';

const noop = () => undefined;
export const FirstScreen: React.FC = () => {
  //const { state: stateTabs, setState: setStateTabs } = useTabsContext();
  const dispatch = useAppDispatch();
  const info = useAppSelector(telegramSettingsServiceSelector);
  const [data, setData] = useState<Record<string, any>>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(recordPrioritySlice.actions.getTelegramSettingsRequest());
  }, []);

  useEffect(() => {
    if (info) {
      setData(info);
    }
  }, [info]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSave = () => {
    setLoading(true);
    dispatch(
      recordPrioritySlice.actions.postUpdateTelegramSettingsRequest({
        ...data,
        success: setLoading(false),
      })
    );
  };

  return (
    <Content>
      <Block>
        <WrapperInput>
          <Input onChange={onChange} isClear value={data?.name} name='name' placeholder='Название' />
        </WrapperInput>
        <WrapperInput>
          <Input
            onChange={onChange}
            isClear
            value={data?.token}
            name='token'
            placeholder='Токен (уникальный ключ) бота'
          />
        </WrapperInput>
        <WrapperInput>
          <Textarea onChange={onChange} value={data?.description} name='description' placeholder='Описание' />
        </WrapperInput>

        <BlockImg>
          <WrapperAddImg>
            <SubTitle>Изображение бота</SubTitle>
            <AddPhoto width={144} height={122} title='Загрузить' onChange={noop} />
          </WrapperAddImg>
          <WrapperAddImg>
            <SubTitle>Изображение на главной</SubTitle>
            <AddPhoto width={144} height={122} title='Загрузить' onChange={noop} />
          </WrapperAddImg>
        </BlockImg>
        <SliceBlock>
          <SliceTitle>Акции</SliceTitle>
          <CheckboxService active={false} setActive={noop} />
        </SliceBlock>
        <WrapperButtons>
          <WrapperButton>
            <DefaultButton onClick={handleSave}>Сохранить</DefaultButton>
          </WrapperButton>
          <WrapperButton>
            <WhiteButton>Запустить</WhiteButton>
          </WrapperButton>
          <WrapperButton>
            <IconButton>
              <IconTrash />
            </IconButton>
          </WrapperButton>
          {loading && <Preloader loading={loading} />}
        </WrapperButtons>
      </Block>
    </Content>
  );
};
const WrapperButton = styled.div`
  margin-right: 8px;
`;
const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 24px;
`;

const SliceBlock = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
`;
const SliceTitle = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
`;

const WrapperAddImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:not(:last-child) {
    margin-right: 65px;
  }
`;

const SubTitle = styled.div`
  color: var(--color-black);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 24px;
`;

const BlockImg = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
`;

const Block = styled.div`
  width: 495px;
`;

const WrapperInput = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const Content = styled.div`
  padding: 50px 25px;
`;
