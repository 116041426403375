import { all } from 'redux-saga/effects';
import { getWorkplaceAll } from './getWorkplaceAll';
import { getWorkplaceId } from './getWorkplaceId';
import { deleteWorkplace } from './deleteWorkplace';
import { createWorkplace } from './postCreateWorkplace';
import { updateWorkplace } from './postUpdateWorkplace';
import { getDataTimeTable } from './getDataTImeTable';
import { searchService } from './searchService';

export default function* workplaceSaga() {
  yield all([
    getWorkplaceAll(),
    createWorkplace(),
    getWorkplaceId(),
    updateWorkplace(),
    deleteWorkplace(),
    getDataTimeTable(),
    searchService(),
  ]);
}
