import createRequestRest from 'api/createRequestRest';
import { put } from 'redux-saga/effects';
import { kanbanSlice } from '../kanbanSlice';
import { transformColumn, transformKart } from '../utils';

function* prepareRequest(payload: any) {
  return payload?.payload?.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  const columns = transformColumn(response?.column || []);
  // const data = transformKart(response);
  yield put(kanbanSlice.actions.getAllColumnTodoListSuccess(columns));
  payload?.payload?.success?.();
}

function* prepareFailure(res: any) {
  yield;
}

export const getAllColumnTodoList = () => {
  return createRequestRest({
    url: '/api/v1/todolist/column/getAll',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: kanbanSlice.actions.getAllColumnTodoListRequest,
    method: 'get',
  });
};
