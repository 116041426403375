import React from 'react';
import { Modal } from 'antd';
type ModalAntProps = {
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
  confirmCLose?: boolean;
};
export const ModalAnt: React.FC<ModalAntProps> = ({ show, onClose, children }) => {
  return (
    <Modal open={show} onCancel={onClose}>
      {children}
    </Modal>
  );
};
