import React, { useState, useEffect} from 'react';
import styled from 'styled-components';
/*import { IconSearch } from 'icons';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { branchSelector, branchSlice } from 'pages/branch/branchSlice';
import { useTabsContext } from 'pages/branch/context';*/
import { RadioInput } from 'components/radioInput';
import { Switch } from 'components/switch';
import { Checkbox } from 'components/checkbox';
import { ScheduleRow, ButtonAddRow } from './row';
import { ScheduleVocation} from './vocation';
import { WeekDays } from './weekDays';
import { ScheduleResult } from './result';
import { weekDays, scheduleTypes/*, mockScheduleItems, getMockScheduleItems*/ } from './data';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { branchSelector, branchSlice } from 'pages/branch/branchSlice';
import { workplaceSelector, workplaceSlice } from 'pages/workplace/workplaceSlice';
import { specialistSelector, specialistSlice } from 'pages/specialist/specialistSlice';
import { scheduleBranchSelector, scheduleBranchSlice } from './scheduleBranchSlice';
import { ScheduleBranchState, ScheduleBranchDataType } from './types';
import { initialState } from './data';
import { ScheduleCalendar } from 'features/scheduleCalendar';
import {
  FlexColumn,
  ScheduleTypeBlock,
  ScheduleContainer,
  ScheduleRowTitle,
  ScheduleRowBlock,
  WeekDaysBlock,
  WeekDaysScheduleRowsBlock,
} from './styles';

const getBranchShedule = (branches: any[]): ScheduleBranchDataType[] => {
  const schedule: ScheduleBranchDataType[] = [];
  branches.forEach(item => {
    const branch: any = {branch_id: item.id, schedule: initialState.data?.[0]?.schedule?.map(s => s.data?.map(d => { return {...d, branch_id: item.id}}))};
    schedule.push(branch);
  });
  return schedule;
}

type ScheduleProps = {
  scheduleSwitcher?: React.JSX.Element;
  showCommonWithAddress?: boolean;
  setChanged: (isChanged: boolean) => void
}

export const Schedule: React.FC<ScheduleProps> = ({scheduleSwitcher, showCommonWithAddress, setChanged}) => {
  const dispatch = useAppDispatch();
  const branch = useAppSelector(branchSelector.branch);
  const branches = useAppSelector(branchSelector.branches);
  const branchSchedule = useAppSelector(branchSelector.schedule);
  const workplaceSchedule = useAppSelector(workplaceSelector.schedule);
  const specialistSchedule = useAppSelector(specialistSelector.schedule);
  const scheduleBranch = useAppSelector(scheduleBranchSelector.data);
  const [scheduleType, setScheduleType] = useState<string>(scheduleTypes.byWeekDays.type);
  const [flexibleSchedule, setFlexibleSchedule] = useState<boolean>(false);
  const [commonWithAddress, setCommonWithAddress] = useState<boolean>(false);
  const [shifts, setShifts] = useState<number[]>([0]);
  const [vocations, setVocations] = useState<number[]>([0]);

  useEffect(() => {
    const schedule = getBranchShedule(branches);
    if (schedule.length) {
      dispatch(scheduleBranchSlice.actions.setSchedule(schedule));
      console.log({scheduleBranch,branch});
    }
  }, [branches]);

  /*useEffect(() => {
    if (branch && !branch.shedule) {
      const defaultBranchSchedule = scheduleBranch.filter(item => item.branch_id === branch.id);
      console.log('Текущий филиал',branch,defaultBranchSchedule?.[0].schedule);
      if (defaultBranchSchedule.length) {
        handleUpdateSchedule(
          branch.id, 
          'branch_id',
          JSON.stringify(defaultBranchSchedule[0].schedule),
          branchSlice.actions.postUpdateBranchRequest
        );
      }
    }
  }, [branch, scheduleBranch]);*/

  const handleUpdateSchedule = (id: number | null, id_type: string, schedule: string, callback: (arg: any) => void) => {
    dispatch(
      callback({
        payload: {
          data: {[id_type]: id, schedule},
          success: (e: any) => console.log(e, id),
        },
      }),
    );
  }

  /*const handleUpdateBranchSchedule = (id: number | null) => {
    dispatch(
      branchSlice.actions.postUpdateBranchRequest({
        payload: {
          data: getMockScheduleItems(id),
          success: (e: any) => console.log(e, id),
        },
      }),
    );
  }

  const handleUpdateWorkplaceSchedule = (id: number | null) => {
    dispatch(
      workplaceSlice.actions.postUpdateWorkplaceRequest({
        payload: {
          data: getMockScheduleItems(id),
          success: (e: any) => console.log(e, id),
        },
      }),
    );
  }

  const handleUpdateSpecialistSchedule = (id: number | null) => {
    dispatch(
      specialistSlice.actions.postUpdateSpecialistRequest({
        payload: {
          data: getMockScheduleItems(id),
          success: (e: any) => console.log(e, id),
        },
      }),
    );
  }*/

  const handleSetByWeekDays = () => {
    setScheduleType(scheduleTypes.byWeekDays.type);
    setShifts([0]);
  }

  const handleAddShift = () => {
    const _shifts: number[] = [...shifts];
    const _last: number | undefined = _shifts?.at(-1);
    if (_last !== undefined) {
      _shifts.push(_last+1);
      setShifts(_shifts);
    }
    setChanged(true);
  }

  const handleRemoveShift = (i: number) => {
    const _shifts: number[] = [...shifts].filter(s => s !== i);
    setShifts(_shifts);
    setChanged(true);
  }

  const handleAddVocation = () => {
    const _vocations: number[] = [...vocations];
    const _last: number | undefined = _vocations?.at(-1);
    if (_last !== undefined) {
      _vocations.push(_last+1);
      setVocations(_vocations);
    }
    setChanged(true);
  }

  const handleRemoveVocation = (i: number) => {
    const _vocations: number[] = [...vocations].filter(s => s !== i);
    setVocations(_vocations);
    setChanged(true);
  }

  const handleFlexibleSchedule = () => {
    if (scheduleType == scheduleTypes.byWeekDays.type) {
      setFlexibleSchedule(!flexibleSchedule);
      setChanged(true);
    }
  }

  return (
    <Content>
      {scheduleSwitcher && <ScheduleTypeBlock>{scheduleSwitcher}</ScheduleTypeBlock>}
      <>
      <ScheduleTypeBlock>
        <RadioInput
          title={scheduleTypes.byWeekDays.title}
          type={scheduleTypes.byWeekDays.type} 
          value={scheduleType} 
          onClick={handleSetByWeekDays}
        />
        <Switch
          title='Гибкий график'
          active={flexibleSchedule}
          setActive={handleFlexibleSchedule}
        />
        <ScheduleContainer>
          <ScheduleRowBlock>
            <ScheduleRowTitle>{flexibleSchedule ? 'Рабочее время' : 'Рабочий день'}</ScheduleRowTitle>
            {flexibleSchedule ? 
            <WeekDaysBlock>
              <WeekDays vertical={true} colored={true} />
              <WeekDaysScheduleRowsBlock>
                {weekDays.map((item, i) => 
                  <ScheduleRow 
                    key={i}
                    type={'time'}
                    number={i}
                    disabled={false}
                    showSuffix={false}
                    titleBegin=''
                    titleEnd=''
                    setChanged={setChanged}
                    //onDelete={handleRemoveShift}
                  />
                )}
              </WeekDaysScheduleRowsBlock>
            </WeekDaysBlock> :
            <ScheduleRow 
              type={'time'}
              disabled={scheduleType !== scheduleTypes.byWeekDays.type}
              showSuffix={false}
              titleBegin='Начало'
              titleEnd='Конец смены'
              setChanged={setChanged}
            />}
          </ScheduleRowBlock>
          <ScheduleRowBlock>
            <ScheduleRowTitle style={{marginLeft: flexibleSchedule ? '10px' : 0}}>{flexibleSchedule ? 'Нерабочее время' : 'График'}</ScheduleRowTitle>
            {flexibleSchedule 
            ? <WeekDaysBlock>
                <WeekDays vertical={true} colored={false} />
                <WeekDaysScheduleRowsBlock>
                  {weekDays.map((item, i) => 
                    <ScheduleRow 
                      key={i}
                      type={'time'}
                      number={i}
                      disabled={false}
                      showSuffix={false}
                      titleBegin=''
                      titleEnd=''
                      setChanged={setChanged}
                      //onDelete={}
                    />
                  )}
                </WeekDaysScheduleRowsBlock>
                <WeekDaysScheduleRowsBlock>
                  {weekDays.map((item, i) => 
                    <ScheduleRow 
                      key={i}
                      type={'time'}
                      number={i}
                      disabled={false}
                      showSuffix={false}
                      titleBegin=''
                      titleEnd=''
                      setChanged={setChanged}
                      //onDelete={}
                    />
                  )}
                </WeekDaysScheduleRowsBlock>
              </WeekDaysBlock>
            : <WeekDays colored={true} />}
          </ScheduleRowBlock>
        </ScheduleContainer>
        {showCommonWithAddress && <Checkbox
          disabled={scheduleType !== scheduleTypes.byWeekDays.type}
          active={commonWithAddress}
          setActive={() => {
            setCommonWithAddress(!commonWithAddress);
            setChanged(true);
          }}
          title='Совпадает с расписанием по адресу'
        />}
      </ScheduleTypeBlock>
      <ScheduleTypeBlock>
        <RadioInput
          title={scheduleTypes.byShifts.title}
          type={scheduleTypes.byShifts.type} 
          value={scheduleType} 
          onClick={() => {
            setScheduleType(scheduleTypes.byShifts.type);
            setChanged(true);
          }}
        />
        <ScheduleContainer>
          <ScheduleRowBlock>
            <ScheduleRowTitle>Даты</ScheduleRowTitle>
            {shifts.map((i: number) => (
              <ScheduleRow 
                key={i}
                type={'date'}
                number={i}
                itemsCount={shifts?.length}
                disabled={scheduleType !== scheduleTypes.byShifts.type}
                showSuffix={true}
                titleBegin='Дата начала'
                titleEnd='Дата окончания'
                setChanged={setChanged}
                onDelete={handleRemoveShift}
              />
            ))}
          </ScheduleRowBlock>
        </ScheduleContainer>
        <ButtonAddRow onAdd={handleAddShift} disabled={scheduleType !== scheduleTypes.byShifts.type} title='Добавить' />
      </ScheduleTypeBlock>
      <ScheduleTypeBlock>
        <ScheduleVocation 
          vocations={vocations}
          addVocation={handleAddVocation}
          removeVocation={handleRemoveVocation}
          setChanged={setChanged}
        />
      </ScheduleTypeBlock>
      <ScheduleTypeBlock><ScheduleResult /></ScheduleTypeBlock>
      </>
      {/* как-то управлять переключением календаря 
      
      +1. Дима добавил поле schedule

общее -
+1. сделать фичу переключение расписания feutures/schedule/switcher

филиалы - 

+1. проверять поле schedule
2. если schedule пуст - вывести в компоненты расписания на обеих вкладках дефолтное расписание из стейта
3. если schedule не пуст - вывести в компоненты расписания на обеих вкладках дефолтное расписание из schedule (распределить по компонентам нижних уровней)
4. добавить сохранение расписания (при нажатии Сохранить/Сохранить и выйти)

рабочее место - 
+1. Вкладка рабочее место - расписание - перенести переключатель из календаря 
+2. Вкладка рабочее место - расписание - добавить календарь
3. сделать переключение
4. проверять поле schedule
5. как-то объединять расписание филиала и расписание рабочего места...
      */}
      <ScheduleCalendar active={0} showButtons={false} setActive={() => {console.log(1)}} />
    </Content>
  );
};

const Content = styled(FlexColumn)`
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  border: 0;
  min-width: 720px;
`;