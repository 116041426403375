import React, {useState} from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import { CalendarDay } from './calendarDay';

type CalendarDaysProps = {
  dates: Moment[];
}

export const CalendarDays: React.FC<CalendarDaysProps> = ({dates}) => {
  const [prevMonthDates] = useState<Moment[]>(() => {
    const dates: Moment[] = [];
    const first = moment().startOf('month').day();
    if (first) {
      for(let i = 0; i < first;i++) {
        if (i > 0) {
          const day = moment().subtract(1, 'months').endOf('month').day(i);
          dates.push(day);
        }
      }
    }
    return dates;
  });
  
  return (
    <Content>
      {prevMonthDates.map((item,i) => <CalendarDay key={i} prev={true} day={item} />)}
      {dates.map((item,i) => <CalendarDay key={i} day={item} />)}
    </Content>
  );
};

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  padding: 0;
  width: 100%;
`;