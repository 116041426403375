import { HistoryTable, ItemTable } from './types';
import styled from 'styled-components';

export const genderMap = {
  male: 'Мужской',
  female: 'Женский',
  none: 'Не указан',
};

export const dataFilter = [
  { id: 0, value: 10 },
  { id: 1, value: 20 },
  { id: 2, value: 40 },
  { id: 3, value: 80 },
];

const FullName = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
  &:hover {
    text-decoration: underline;
  }
`;

const Id = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #6b6f76;
`;

export const historyHeader: HistoryTable[] = [
  {
    id: 0,
    key: 'date_at',
    value: 'Дата приёма',
  },
  {
    id: 1,
    key: 'reason',
    value: 'Причина обращения',
  },
  {
    id: 2,
    key: 'service',
    value: 'Оказанные работы',
  },
  {
    id: 3,
    key: 'amount',
    value: 'Стоимость',
  },
  {
    id: 4,
    key: 'paid',
    value: 'Оплачено',
  },
  {
    id: 5,
    key: 'specialist',
    value: 'Специалист',
  },
];

export const header: ItemTable[] = [
  /*{
    id: 0,
    key: 'id',
    value: 'ID',
    wrap: Id,
  },*/
  {
    id: 1,
    key: 'fullName',
    value: 'ФИО',
    group: ['surname', 'name', 'patronymic'],
    wrap: FullName,
  },
  {
    id: 2,
    key: 'phone',
    value: 'Телефон',
  },
  {
    id: 3,
    key: 'birthday_at',
    value: 'Дата рождения',
  },
  {
    id: 4,
    key: 'todolist',
    value: 'Приемов',
  },
  {
    id: 5,
    key: 'gender',
    value: 'Пол',
    map: genderMap,
  },
  {
    id: 6,
    key: 'reason',
    value: 'Причина обращения',
  },
  {
    id: 7,
    key: 'comment',
    value: 'Услуга',
  },
];
export const transformHistoryRow = (data: Record<string, any>[]): Record<string, any>[] => {
  return data?.map((item) => ({
    id: item?.id,
    data_at: item?.data_at,
    amount: `${Math.round(item?.amount)} ₽`,
    paid: `${Math.round(item?.paid)} ₽`,
    service: item?.service?.title,
    reason: item?.reason,
    time_at: item?.time_at,
    specialist: [item?.specialist?.name, item?.specialist?.surname].join(' '),
  }));
};
export const getHistoryRowsData = (header: HistoryTable[], data: Record<string, any>): HistoryTable[] =>
  header.map((item) => {
    if (item?.service?.length) {
      if (item.wrap !== undefined) {
        const Wrap = item.wrap;
        return {
          ...item,
          todoId: data.id,
          value: <Wrap>{item.service.map((i) => data?.[i] || '').join(' ') || ''}</Wrap>,
        };
      } else return { ...item, todoId: data.id, value: item.service.map((i) => data?.[i] || '').join(' ') || '' };
    } else {
      if (item.wrap !== undefined) {
        const Wrap = item.wrap;
        if (item?.map !== undefined) {
          return { ...item, todoId: data.id, value: <Wrap>{item.map[data?.[item.key] || 'none']}</Wrap> };
        } else {
          return { ...item, todoId: data.id, value: <Wrap>{data?.[item.key] || ''}</Wrap> };
        }
      } else if (item?.map !== undefined) {
        return { ...item, todoId: data.id, value: item.map[data?.[item.key] || 'none'] };
      } else {
        return { ...item, todoId: data.id, value: data?.[item.key] || '' };
      }
    }
  });

export const getRowsData = (
  header: ItemTable[],
  data: Record<string, any>,
  onClickName?: (id: number) => void,
): ItemTable[] =>
  header.map((item) => {
    if (item?.group?.length) {
      if (item.wrap !== undefined) {
        const Wrap = item.wrap;
        return {
          ...item,
          clientId: data.id,
          value: (
            <Wrap onClick={() => onClickName?.(data.id)}>{item.group.map((i) => data?.[i] || '').join(' ') || ''}</Wrap>
          ),
        };
      } else return { ...item, clientId: data.id, value: item.group.map((i) => data?.[i] || '').join(' ') || '' };
    } else {
      if (item.wrap !== undefined) {
        const Wrap = item.wrap;
        if (item?.map !== undefined) {
          return { ...item, clientId: data.id, value: <Wrap>{item.map[data?.[item.key] || 'none']}</Wrap> };
        } else {
          return { ...item, clientId: data.id, value: <Wrap>{data?.[item.key] || ''}</Wrap> };
        }
      } else if (item?.map !== undefined) {
        return { ...item, clientId: data.id, value: item.map[data?.[item.key] || 'none'] };
      } else {
        return { ...item, clientId: data.id, value: data?.[item.key] || '' };
      }
    }
  });
