import React from 'react';
import styled from 'styled-components';
import { DefaultButton, WhiteButton } from '../buttons';
import { Modal } from 'antd';

type PopUpAntProps = {
  title: string;
  visible: boolean;
  onClick: () => void;
  onCancel: () => void;
};

export const PopUpAnt: React.FC<PopUpAntProps> = ({ title, visible, onClick, onCancel }) => {
  return (
    <Modal open={visible} onCancel={onCancel}>
      <Content>
        <Title>{title}</Title>
        <WrapperButtons>
          <WrapBtn>
            <DefaultButton onClick={onClick}>Да</DefaultButton>
          </WrapBtn>
          <WrapBtn>
            <WhiteButton onClick={onCancel}>Нет</WhiteButton>
          </WrapBtn>
        </WrapperButtons>
      </Content>
    </Modal>
  );
};

const WrapBtn = styled.div`
  button {
    height: 40px;
    width: 88px;
  }
`;
const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: var(--color-black-light);
  white-space: nowrap;
  margin-bottom: 32px;
`;

const Content = styled.div`
  position: relative;
  padding: 24px;
  border-radius: 8px;
  min-width: 240px;
`;
