import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { IconPlus } from '../../../icons';
import moment, { Moment } from 'moment';
import { InfoPopUp } from '../../../components/infoPopUp';
import { ItemCardKanban } from './itemCardKanban';
import { kanbanSlice } from '../../kanban/kanbanSlice';
import { useAppDispatch } from '../../../core/hooks';
import { ModalChangeCardKanban } from '../../../features/modals/modalChangeCardKanban';
import { useTabsContext } from '../../kanban/tabsContent/context';
import { formatDateToFront } from '../../../core/utils';

type ItemCardProps = {
  index: number;
  time: string;
  date: Moment;
  data: Record<string, any>[];
  updateData: () => void;
};

const checkTimeZone = (timeCard?: string, timeRow?: string) => {
  const checkTime = moment(timeCard, 'HH:mm');
  const startTime = moment(timeRow, 'HH:mm');
  const endTime = moment(timeRow, 'HH:mm').add(1, 'hour');
  return checkTime.isSameOrAfter(startTime) && checkTime.isBefore(endTime);
};

export const ItemCard: React.FC<ItemCardProps> = ({ time, index, data = [], updateData, date }) => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const { state: stateTabs, setState: setStateTabs } = useTabsContext();

  const getKard = (id: string) => {
    dispatch(
      kanbanSlice.actions.getCardTodoListRequest({
        payload: {
          data: { id },
          success: () => {
            setShowModal(true);
          },
        },
      }),
    );
  };

  const dataZone = useMemo(() => data.filter((item) => checkTimeZone(item?.time_at, time)), [data, time]);

  const handleClickOpenModal = () => {
    let time_at = time;
    if (time_at.length === 4) {
      time_at = `0${time}`;
    }
    dispatch(kanbanSlice.actions.setDataActiveCard({ date_at: formatDateToFront(date), time_at }));
    setShowModal(true);
  };

  return (
    <Item firstRow={index} count={dataZone?.length + 1}>
      {data.map((i: Record<string, any>) => {
        if (checkTimeZone(i?.time_at, time)) {
          const workplace = i?.workplace?.title || 'Без рабочего места';
          const specialist =
            [i?.specialist?.surname, i?.specialist?.name, i?.specialist?.patronymic].filter(Boolean).join(' ') ||
            'Без специалиста';

          return (
            <InfoPopUp
              zIndex={1}
              key={i?.id}
              component={
                <DataItem>
                  <DateValue>{workplace}</DateValue>
                  <DateValue>{specialist}</DateValue>
                  <DateValue>{i?.time_at}</DateValue>
                </DataItem>
              }
            >
              <ItemCardKanban data={i} getKard={getKard} />
            </InfoPopUp>
          );
        }
        return null;
      })}
      <ContentItem>
        <ContentAdd onClick={handleClickOpenModal}>
          <IconPlus />
        </ContentAdd>
        {/*<Locked onClick={() => setLocked((prev) => !prev)}>*/}
        {/*  {locked ? <IconLockedOn /> : <IconLockedOff />}*/}
        {/*</Locked>*/}
      </ContentItem>
      <ModalChangeCardKanban showModal={showModal} setShowModal={setShowModal} updateData={updateData} />
    </Item>
  );
};

const DataItem = styled.div`
  height: 100%;
  font-size: 10px;
  padding: 3px;
  cursor: pointer;
  overflow: hidden;
  background: rgba(255, 99, 71, 0.44);

  &:hover {
    background: rgba(255, 99, 71, 0.6);
  }

  &:not(:last-child) {
    margin-right: 3px;
  }

  &:global {
    .ant-tooltip-open {
      z-index: 1;
    }
  }
`;
const DateValue = styled.div`
  font-size: 8px;
  font-weight: 500;
  color: #282a2f;
  margin-bottom: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ContentItem = styled.div`
  //width: 100%;
  height: 100%;
  //align-items: center;
  //white-space: nowrap;
  opacity: 0;
`;

const ContentAdd = styled.div`
  height: 100%;
  cursor: pointer;
  background: #f0f3f9;
  margin-right: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path {
      stroke: #6471e0;
    }
  }
`;

const Locked = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 0.2;
  background: #f0f3f9;
  height: 100%;
`;

const Item = styled.div<{ firstRow: number; count: number }>`
  width: 100%;
  height: 100%;
  ${({ count }) => {
    if (count !== 0) {
      return css`
        display: grid;
        grid-template-columns: repeat(${count}, 1fr);
        grid-auto-rows: 1fr;
      `;
    } else {
      return css`
        display: flex;
      `;
    }
  }}

  align-items: center;
  justify-content: space-between;
  padding: 2px;
  border-bottom: 1px solid #eff1f4;
  ${({ firstRow }) =>
    firstRow === 0
      ? css`
          border-top: 1px solid #eff1f4;
        `
      : null}

  &:first-child {
    border-left: 1px solid #eff1f4;
  }
  &:not(:last-child) {
    border-right: 1px solid #eff1f4;
  }
  &:hover ${ContentItem} {
    opacity: 1;
  }
`;
