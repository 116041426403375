import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { getTwoWeekDays } from './lib';
import { DateDay } from 'components/ui/date';
import moment from 'moment';
import 'moment/locale/ru';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { IconCloseSmall } from 'icons';
import { TimeEntry } from 'features/timeEntry';
import { specialistSelector, specialistSlice } from 'pages/specialist/specialistSlice';
import { serviceIdSelector } from 'pages/service/serviceSlice';
import { formatDateToBack } from 'core/utils';
import { setDate } from 'features/entry/entrySlice';

moment.locale('ru');

type CalendarDaysProps = {
  setShowCalendar: (show: boolean) => void;
  changeDate: (value?: string) => void;
  changeTime: (value?: string) => void;
};

export const CalendarDays: React.FC<CalendarDaysProps> = ({ setShowCalendar, changeDate, changeTime }) => {
  const dispatch = useAppDispatch();
  const [active, setActive] = useState<number>(0);
  const refBlock = useRef<HTMLDivElement>(null);
  const refLastItem = useRef<HTMLDivElement>(null);
  const dates = getTwoWeekDays();
  const month = moment().format('MMM');
  const dayNum = +moment().format('D');
  const dateString = moment().format('DD.MM.YYYY').toString();
  const serviceId = useAppSelector(serviceIdSelector);
  const specialistId = useAppSelector(specialistSelector.specialistId);
  const specialistSchedule = useAppSelector(specialistSelector.schedule);
  const [schedule, setShedule] = useState<Record<string, any>[] | null>(null);

  useEffect(() => {
    setActive(dayNum);
    dispatch(setDate(dateString));
  }, [dayNum, dateString]);

  useEffect(() => {
    if (serviceId && specialistId) {
      dispatch(
        specialistSlice.actions.getScheduleRequest({
          specialist_ids: specialistId.toString(),
          group_by: 'specialist_id',
          group_by_sub: 'date_at',
          begin_at: formatDateToBack(dates[0]),
          end_at: formatDateToBack(dates[dates.length-1]),
        }),
      );
    }
    return () => {
      dispatch(specialistSlice.actions.resetData());
    };
  }, [serviceId, specialistId]);

  useEffect(() => {
    if (specialistId && specialistSchedule) {
      if (Object?.keys(specialistSchedule)?.length) {
        setShedule(
          Object
            .values(Object.keys(specialistSchedule)
            .map(key => specialistSchedule[key])?.[0])
            .flat()
            .filter((item: any) => item?.specialist?.specialist_id === specialistId)
            .map((item: any) => {
              return {
                id: item.id,
                service_id: item?.service?.service_id,
                specialist_id: item?.specialist?.specialist_id,
                date_at: item.date_at,
                time_at: item.time_at,
              };
            })
        );
      }
    }
    return () => {
      setShedule(null);
    };
  }, [specialistSchedule, specialistId]);

  return (
    <WrapperContent onBlur={() => setShowCalendar(false) }>
      <Mont>{month.charAt(0).toUpperCase() + month.slice(1)}</Mont>
      <Content ref={refBlock}>
        {dates.map((item, index) => {
          return (
            <WrapperItem
              ref={(ref) => {
                if (index === dates.length) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  refLastItem.current = ref;
                }
              }}
              key={item.toISOString()}
            >
              <DateDay date={item} active={active} setActive={setActive} changeDate={changeDate} />
            </WrapperItem>
          );
        })}
      </Content>
      <TimeEntry setShowCalendar={setShowCalendar} changeTime={changeTime} schedule={schedule} />
      <Close onClick={() => setShowCalendar(false) }><IconCloseSmall/></Close>
    </WrapperContent>
  );
};

const WrapperContent = styled.div`
  position: absolute;
  top: 35px;
  right: -10px;
  z-index: 98;
  background-color: var(--color-white);
  border-radius: 0;
  padding: 10px;
  max-width: 440px;
  border: 1px var(--color-grey) solid;
  border-radius: 8px;
  box-shadow: 2px 6px 4px rgba(0,0,0,0.15);
`;
const Mont = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
  padding-left: 5px;
`;

const WrapperItem = styled.div`
  &:not(:last-child) {
    margin-right: 5px;
  }
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;

  /* хром, сафари */
  &::-webkit-scrollbar {
    width: 0;
  }

  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
`;
const Close = styled.button`
  position: absolute;
  right: 5px;
  top: 8px;
  z-index: 99;
`;

