import React from 'react';
import styled from 'styled-components';
import { Table } from './table';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

export const WriteQueue: React.FC = () => {
  return (
    <Content>
      <DndProvider backend={HTML5Backend}>
        <Table />
      </DndProvider>
    </Content>
  );
};

const Content = styled.div`
  padding: 20px 40px;
`;
