import React from 'react';
import styled, { css } from 'styled-components';
import { weekDays } from '../data';
import { WeekDayItem } from './weekDayItem';
import { WeekDaysProps } from '../types';

export const WeekDays: React.FC<WeekDaysProps> = ({vertical, colored}) => {
  return (
    <Content vertical={vertical || false}>
      {weekDays.map(item => 
        <WeekDayItem 
          key={item.id}
          id={item.id}
          active={item.active}
          colored={colored ? item.colored : false}
          name={item.name}
        />
      )}
    </Content>
  );
};

const Content = styled.div<{vertical: boolean}>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${({ vertical }) => {
    if (vertical) {
      return css`
        flex-direction: column;
        gap: 16px;
      `;
    } else {
      return css`
        gap: 8px;
      `;
    }
  }};
`;