import { FilterDataProps } from 'components/filters/filterSchedule';

export const initFilters: FilterDataProps[] = [
  {
    id: 0,
    active: false,
    label: 'Кабинет 1',
    value: 'Кабинет 1',
  },
  {
    id: 1,
    active: false,
    label: 'Кабинет 2',
    value: 'Кабинет 2',
  },
  {
    id: 2,
    active: false,
    label: 'Кабинет 3',
    value: 'Кабинет 3',
  },
  {
    id: 3,
    active: false,
    label: 'Кабинет 4',
    value: 'Кабинет 4',
  },
  {
    id: 4,
    active: false,
    label: 'Кабинет 5',
    value: 'Кабинет 5',
  },
];
