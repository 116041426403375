import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { IconPhoto } from 'icons';
import config from 'api/config';
import { message } from 'antd';

type AddPhotoProps = {
  onChange: (base64: any) => void;
  id?: string;
  title?: string;
  height?: number;
  width?: number;
  urlImg?: string;
};

export const AddPhoto: React.FC<AddPhotoProps> = ({
  onChange,
  id = 'input_file',
  title = 'Загрузить фото',
  height = 96,
  width = 96,
  urlImg,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [url, setUrl] = useState<any>();
  const handleChangeFile = (e: any) => {
    const file = e.target.files[0];
    const size = file?.size;

    if (size > 2_000_000) {
      message.config({
        duration: 4,
      });
      e.target.value = '';
      return message.error('Размер файла превышает 2 Mб, можно загружать файлы менее 2 Mб.');
    }
    const reader = new FileReader();
    reader.onload = () => {
      const dataURL = reader.result; //base64
      onChange(file);
      setUrl(dataURL);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (urlImg) {
      setUrl(`${config.baseUrlImg}${urlImg}`);
    }
  }, [urlImg]);

  useEffect(() => {
    return () => {
      setUrl(undefined);
    };
  }, []);

  return (
    <Content url={!!url} style={{ height, width }}>
      <WrapperImg url={!!url}>
        <Image src={url} />
        <Label htmlFor='input_file'>
          <WrapperIcon>
            <IconPhoto />
          </WrapperIcon>
        </Label>
      </WrapperImg>

      <Body url={!!url}>
        <Head>
          <IconPhoto />
        </Head>
        <Footer>
          <Label htmlFor={id}>{title}</Label>
          <Input ref={inputRef} id={id} type={'file'} onChange={handleChangeFile} />
        </Footer>
      </Body>
    </Content>
  );
};
const Body = styled.div<{ url: boolean }>`
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  ${({ url }) =>
    url
      ? css`
          opacity: 0;
          padding: 0;
          height: 0;
          width: 0;
        `
      : css`
          opacity: 1;
        `}
`;
const WrapperIcon = styled.div`
  z-index: 1;
  cursor: pointer;
  position: absolute;
  right: 4px;
  bottom: 4px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  svg {
    height: 12px;
    width: 12px;
  }
`;
const WrapperImg = styled.div<{ url: boolean }>`
  ${({ url }) =>
    url
      ? css`
          opacity: 1;
          position: relative;
          height: 100%;
        `
      : css`
          opacity: 0;
          padding: 0;
          height: 0;
          width: 0;
        `}
`;
const Image = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
`;
const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
`;

const Footer = styled.div`
  text-align: center;
`;
const Content = styled.div<{ url: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${({ url }) => (url ? '0px' : '12px')};
  border: 1px solid #dfe1e4;
  border-radius: 8px;
`;

const Label = styled.label`
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  border-radius: 5px;
  opacity: 1;
  max-height: 30px;
  color: var(--color-blue);
  transition: all 0.33s ease;
  &:hover {
    opacity: 0.8;
  }
`;
const Input = styled.input`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
`;
