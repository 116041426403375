import React from 'react';
import styled, { css } from 'styled-components';

type SwitchTextProps = {
  onText: string;
  offText: string;
  active: boolean;
  onClick: () => void;
};

export const SwitchText: React.FC<SwitchTextProps> = ({ active, onText, offText, onClick }) => {
  return (
    <Content onClick={onClick}>
      <ItemText active={active}>{onText}</ItemText>
      <ItemText active={!active}>{offText}</ItemText>
    </Content>
  );
};
const ItemText = styled.div<{ active: boolean }>`
  background: #ffffff;

  border-radius: 4px;
  padding: 1px 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  &:first-child {
    margin-right: 4px;
  }
  ${({ active }) =>
    active
      ? css`
          background: #ffffff;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12);
        `
      : css`
          background: #eff1f4;
        `}
`;
const Content = styled.div`
  cursor: pointer;
  background: #eff1f4;
  border-radius: 5px;
  padding: 2px;
  display: flex;
  align-items: center;
`;
