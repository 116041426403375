import React from 'react';
import type { TimePickerProps } from 'antd';
import { TimePicker } from 'antd';
import locale from 'antd/es/locale/ru_RU';
//import CustomInputMask from './customInputMask';

type PickerProps = TimePickerProps;

export const BaseTimePicker: React.FC<PickerProps> = (props) => {
  return (
    <TimePicker
      locale={locale.DatePicker}
      format={'HH:mm'}
      //inputRender={(p) => <CustomInputMask {...p} />} // из-за этого была ошибка undefined focus
      {...props}
    />
  );
};
