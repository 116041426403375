import moment, { Moment } from 'moment';
import 'moment/locale/ru';

moment.locale('ru');

const compareTime = (a: string, b: string): boolean => {
    const amin: number = +a.split(':')[0] * 60 + +a.split(':')[1]*1;
    let bmin: number = +b.split(':')[0] * 60 + +b.split(':')[1]*1;
    if( bmin + (60 * 12) < amin){
        bmin += (60 * 24);
    }
    return amin < bmin;
}

const compareDates = (a: string, b: string): boolean => {
    const date1: Moment = moment(a, "YYYY-MM-DD");
    const date2: Moment = moment(b, "YYYY-MM-DD");    
    return date1.isBefore(date2);
}

export {compareTime, compareDates};