import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LayoutAuth } from './layoutAuth';
import { Title } from './style';
import { Input } from 'components/input';
import { Checkbox } from 'components/checkbox';
import { DefaultButton, WhiteButton } from 'components/buttons';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { authSlice, spaseAuthSelector } from './authSlice';
import { valid } from 'core/utils';
import { Space } from '../../components/style';
import { message } from 'antd';

const initInputs = {
  login: '',
  email: '',
  password: '',
  remember: false,
};

const initInputsReset = {
  login: '',
  password: '',
  token: '',
};

export const SignIn: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const space = useAppSelector(spaseAuthSelector);
  const [remind, setRemind] = useState(false);
  const [edit, setEdit] = useState(false);
  const [login, setLogin] = useState('');
  const [inputs, setInputs] = useState(initInputs);
  const [emailValid, setEmailValid] = useState(false);
  const [emailValidReset, setEmailValidReset] = useState(false);
  const [inputsReset, setInputsReset] = useState(initInputsReset);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleOnChangeReset = (e: ChangeEvent<HTMLInputElement>) => {
    setInputsReset((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleClickRegister = () => {
    navigate('/auth/sign_up');
  };

  const resetPassport = () => {
    setEdit(true);
    dispatch(
      authSlice.actions.getResetRequest({
        payload: {
          data: {
            login,
          },
          success: (token: string) => setInputsReset((prev) => ({ ...prev, token })),
        },
      }),
    );
  };

  const loginSuccess = () => {
    dispatch(authSlice.actions.getSpaceAllRequest());
    localStorage.setItem('auth', 'true');
    localStorage.setItem('email_login_user', inputs.email);
    // window.location.reload();
    // navigate('/');
  };

  const handleClickLogin = () => {
    const data: Record<string, any> = { ...inputs };
    delete data.remember;
    delete data.login;
    dispatch(
      authSlice.actions.getLoginRequest({
        payload: {
          data: {
            ...data,
          },
          success: loginSuccess,
        },
      }),
    );
  };

  const resetPassportEdit = () => {
    dispatch(
      authSlice.actions.getEditRequest({
        payload: {
          data: {
            ...inputsReset,
          },
          success: () => {
            navigate('/');
          },
        },
      }),
    );
  };

  const handleClickSpace = (id: number) => () => {
    dispatch(
      authSlice.actions.postSpaceRequest({
        payload: {
          data: { id },
          success: () => {
            localStorage.setItem('auth', 'true');
            navigate('/');
          },
        },
      }),
    );
  };

  useEffect(() => {
    if (space === null) return;
    if (space?.length === 1) {
      dispatch(
        authSlice.actions.postSpaceRequest({
          payload: {
            data: { id: space?.[0]?.id },
            success: () => {
              localStorage.setItem('auth', 'true');
              navigate('/');
            },
          },
        }),
      );
    } else {
      message.config({
        duration: 4,
      });
      void message.error('Пространства отсутствуют, обратитесь в поддержку');
    }
  }, [space]);

  useEffect(() => {
    if (valid.email(inputs.email) || inputs.email === '') {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  }, [inputs.email]);

  useEffect(() => {
    if (valid.email(login) || login === '') {
      setEmailValidReset(true);
    } else {
      setEmailValidReset(false);
    }
  }, [login]);

  const disabledBtn = !valid.email(inputs.email) || !inputs.password;

  return (
    <LayoutAuth>
      <Content>
        {edit ? (
          <>
            <Body>
              <Title style={{ marginBottom: '10px' }}>Восстановление пароля</Title>
              <WrapperInput>
                <Input
                  isClear
                  name={'login'}
                  placeholder={'E-mail'}
                  value={inputsReset.login}
                  onChange={handleOnChangeReset}
                  onClear={() => setLogin('')}
                />
              </WrapperInput>
              <WrapperInput>
                <Input
                  isClear
                  name={'token'}
                  placeholder={'Временный ключ'}
                  value={inputsReset.token}
                  onClear={() => setLogin('')}
                />
              </WrapperInput>
              <WrapperInput>
                <Input
                  isClear
                  name={'password'}
                  placeholder={'Новый пароль'}
                  value={inputsReset.password}
                  onChange={handleOnChangeReset}
                  onClear={() => setLogin('')}
                />
              </WrapperInput>
              <RemindButton>
                <DefaultButton onClick={resetPassportEdit}>Сменить пароль</DefaultButton>
              </RemindButton>
            </Body>
          </>
        ) : (
          <>
            {space?.length > 1 ? (
              <>
                <Body>
                  <Title>Выберите пространство</Title>
                  <SpaceBody>
                    {space.map((item: Record<string, any>) => (
                      <Space key={item.id} onClick={handleClickSpace(item.id)}>
                        {item.name}
                      </Space>
                    ))}
                  </SpaceBody>
                </Body>
              </>
            ) : (
              <>
                {remind ? (
                  <>
                    <Body>
                      <Title>Восстановление пароля</Title>
                      <Text>Введите E-mail который вы указывали при регистрации</Text>
                      <WrapperInput>
                        <Input
                          isClear
                          placeholder={'E-mail'}
                          value={login}
                          valid={emailValidReset}
                          onChange={(e) => {
                            setLogin(e.target.value);
                            setInputsReset((prev) => ({ ...prev, login: e.target.value }));
                          }}
                          onClear={() => setLogin('')}
                        />
                      </WrapperInput>
                      <RemindButton>
                        <DefaultButton disabled={!valid.email(login)} onClick={resetPassport}>
                          Напомнить пароль
                        </DefaultButton>
                      </RemindButton>
                    </Body>
                  </>
                ) : (
                  <>
                    <Body>
                      <Title>Вход в личный кабинет</Title>
                      <Block>
                        <WrapperInput>
                          <Input
                            name={'email'}
                            isClear
                            placeholder={'Логин'}
                            value={inputs.email}
                            valid={emailValid}
                            onChange={handleOnChange}
                            onClear={() => setInputs((prevState) => ({ ...prevState, email: '' }))}
                          />
                        </WrapperInput>
                        <WrapperInput>
                          <Input
                            type={'password'}
                            name={'password'}
                            isClear
                            placeholder={'Пароль'}
                            value={inputs.password}
                            onChange={handleOnChange}
                            onClear={() => setInputs((prevState) => ({ ...prevState, password: '' }))}
                          />
                        </WrapperInput>
                      </Block>
                      <Checkbox
                        title={'Запомнить'}
                        active={inputs.remember}
                        setActive={() => setInputs((prevState) => ({ ...prevState, remember: !prevState.remember }))}
                      />
                      <Buttons>
                        <DefaultButton disabled={disabledBtn} onClick={handleClickLogin}>
                          Войти
                        </DefaultButton>
                        <WhiteButton onClick={() => setRemind(true)}>Напомнить пароль</WhiteButton>
                      </Buttons>
                    </Body>
                    <Footer>
                      <WrapperRegister>
                        <DefaultButton onClick={handleClickRegister}>Зарегестрироваться</DefaultButton>
                      </WrapperRegister>
                      <LinkDemo>Войти в демо-режим</LinkDemo>
                    </Footer>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Content>
    </LayoutAuth>
  );
};
const SpaceBody = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const RemindButton = styled.div`
  width: 152px;
`;
const Text = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  text-align: left;
  margin-bottom: 16px;
  margin-top: 16px;
  width: 322px;
`;
const LinkDemo = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #282a2f;
  margin-top: 16px;
  &:hover {
    text-decoration: underline;
  }
`;
const WrapperRegister = styled.div`
  width: 320px;
`;
const Footer = styled.div`
  margin-top: 172px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Buttons = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 320px;
  column-gap: 16px;
`;
const Block = styled.div`
  margin: 16px 0;
`;
const WrapperInput = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
const Body = styled.div``;
const Content = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
