import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'core/store';
import { clientFrontBack } from './helpers';

type ClientsStateProps = {
  clients: any[];
  count: number;
  client: null | Record<string, any>;
};

const initialState: ClientsStateProps = {
  clients: [],
  count: 0,
  client: null,
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    //сброс данных
    //---------------------------------
    resetClientsActive: (state: ClientsStateProps) => {
      state.client = null;
    },

    //Получение всех clients
    //---------------------------------
    getAllClientsRequest: (state: ClientsStateProps, action) => {
      //
    },
    getAllClientsSuccess: (state: ClientsStateProps, action) => {
      state.clients = action.payload?.data;
      state.count = action.payload?.count;
    },
    //Получение client по id
    //---------------------------------
    getClientRequest: (state: ClientsStateProps, action) => {
      //
    },
    getClientSuccess: (state: ClientsStateProps, action) => {
      state.client = clientFrontBack(action.payload);
    },
    //Создание client
    //---------------------------------
    postCreateClientRequest: (state: ClientsStateProps, action) => {
      //
    },
    postCreateClientSuccess: (state: ClientsStateProps, action) => {
      state.client = action.payload;
    },
    updateClientsTable: (state: ClientsStateProps, action) => {
      state.clients = [...state.clients, action.payload?.payload?.data];
    },
    //Обновление client
    //---------------------------------
    postUpdateClientRequest: (state: ClientsStateProps, action) => {
      //
    },
    postUpdateClientSuccess: (state: ClientsStateProps, action) => {
      state.client = clientFrontBack(action.payload);
    },
    //Удаление client
    //---------------------------------
    deleteClientRequest: (state: ClientsStateProps, action) => {
      //
    },
    deleteClientSuccess: (state: ClientsStateProps, action) => {
      // state.clients = action.payload;
    },
  },
});

export const clientsClientsSelector = (s: RootState) => s.clients.clients;
export const clientClientsSelector = (s: RootState) => s.clients.client;
export const countClientsSelector = (s: RootState) => s.clients.count;
