import React from 'react';
import styled, {css} from 'styled-components';
import { calendarTypes } from '../data';

type ScheduleSwitcherProps = {
  active: number;
  setActive?: (i: number) => void;
}

export const ScheduleSwitcher: React.FC<ScheduleSwitcherProps> = ({active = 0, setActive}) => {
  return (
    <Content>
      {calendarTypes.map((item,i) => 
        <SelectCalendarTypeButtons 
          key={i} 
          active={active === i} 
          onClick={() => setActive?.(i)}
        >{item}
        </SelectCalendarTypeButtons>
      )}
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap:0;
  background-color: #EFF1F4;
  border-radius: 5px;
  padding: 2px;
`;
export const SelectCalendarTypeButtons = styled.button<{active: boolean}>`
  padding: 6px 18px;
  font-size: 12px;
  ${({ active }) => {
    if (active) {
      return css`
        background-color: var(--color-white);
        border-radius: 5px;
      `;
    }
  }};
`;