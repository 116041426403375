import { all } from 'redux-saga/effects';
import serviceSaga from 'pages/service/sagas';
import clientSaga from 'pages/clients/sagas';
import specialistSaga from 'pages/specialist/sagas';
import workplaceSaga from 'pages/workplace/sagas';
import kanbanSaga from 'pages/kanban/sagas';
import authSaga from 'pages/auth/sagas';
import spaceSaga from 'pages/auth/sagas/space';
import scheduleSaga from '../pages/schedule/sagas';
import recordingPrioritySaga from 'pages/recordingPriority/sagas';
import todolistSaga from 'pages/todolist/sagas';
import branchSaga from 'pages/branch/sagas';

export default function* rootSaga() {
  yield all([
    authSaga(),
    serviceSaga(),
    clientSaga(),
    specialistSaga(),
    workplaceSaga(),
    kanbanSaga(),
    spaceSaga(),
    scheduleSaga(),
    recordingPrioritySaga(),
    todolistSaga(),
    branchSaga(),
  ]);
}
