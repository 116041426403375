import createRequestRest from 'api/createRequestRest';
import { put } from 'redux-saga/effects';
import { todolistSlice } from '../todolistSlice';

function* prepareRequest(payload: any) {
  return payload?.payload?.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(todolistSlice.actions.getTodoListIdSuccess(response));
  payload?.payload?.success?.();
}

function* prepareFailure(res: any) {
  yield;
}

export const getTodolistId = () => {
  return createRequestRest({
    url: '/api/v1/todolist/:id',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: todolistSlice.actions.getTodoListIdRequest,
    method: 'get',
  });
};
