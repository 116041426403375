import React from 'react';
import styled from 'styled-components';
import { TypeColumnProps } from '../types';
import { IconPlus } from 'icons';
import { Dropdown } from 'components/dropdown';
import { useAppDispatch, useAppSelector } from '../../../core/hooks';
import { ColumnProps, kanbanSelector, kanbanSlice } from '../kanbanSlice';

const manifestContent: Record<TypeColumnProps, { icon: any; title: string }> = {
  pending: {
    icon: '/icons/kanban/pending.svg',
    title: 'В ожидании',
  },
  in_work: {
    icon: '/icons/kanban/in_work.svg',
    title: 'В работе',
  },
  completed: {
    icon: '/icons/kanban/completed.svg',
    title: 'Завершенные',
  },
  canceled: {
    icon: '/icons/kanban/canceled.svg',
    title: 'Отмененные',
  },
  new_column: {
    icon: '/icons/kanban/canceled.svg',
    title: 'Отмененные',
  },
};

type HeadColumnProps = {
  item: ColumnProps;
  count: number;
  onClick: () => void;
};

export const HeadColumn: React.FC<HeadColumnProps> = ({ count, item, onClick }) => {
  const archive = useAppSelector(kanbanSelector.archive);
  const dispatch = useAppDispatch();

  const removeColumn = (id: string | symbol) => () => {
    if (typeof id === 'number')
      dispatch(
        kanbanSlice.actions.removeColumnRequest({
          payload: {
            data: { id },
            success: () => {
              dispatch(kanbanSlice.actions.getAllColumnTodoListRequest());
            },
          },
        }),
      );
  };

  return (
    <Content>
      <WrapperContent>
        {/*<WrapperIcon>*/}
        {/*  <Icon src={manifestContent[type].icon} />*/}
        {/*</WrapperIcon>*/}
        <Title>{item.name}</Title>
        <Count>{count}</Count>
      </WrapperContent>
      {item.id !== archive?.id && (
        <WrapperIcon>
          <Dropdown
            content={<IconPlus />}
            menu={[
              {
                key: '1',
                label: 'Удалить колонку',
                onClick: removeColumn(item.id),
              },
              {
                key: '2',
                label: 'Создать карточку',
                onClick,
              },
            ]}
          ></Dropdown>
        </WrapperIcon>
      )}
    </Content>
  );
};
const Icon = styled.img``;
const Count = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8e8e8e;
`;
const Title = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #282a2f;
  margin-left: 8px;
  margin-right: 14px;
`;
const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const WrapperContent = styled.div`
  display: flex;
  align-items: center;
`;
const Content = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  width: 100%;
`;
