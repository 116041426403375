import React from 'react';
import styled, { css } from 'styled-components';
import { Control } from './constrol';

type ItemCategoryServiceSearchProps = {
  data: Record<string, any>;
  changeService: (data?: Record<string, any>) => void;
  changeActive: (data?: Record<string, any>) => void;
  deleteService: (data?: Record<string, any>) => void;
  copyService: (data?: Record<string, any>) => void;
  left?: number;
  level?: number;
  addServiceCategory: (data?: Record<string, any>, level?: number) => void;
};

export const ItemCategoryServiceSearch: React.FC<ItemCategoryServiceSearchProps> = ({
  data,
  changeService,
  changeActive,
  deleteService,
  copyService,
  addServiceCategory,
  left = 0,
  level = 1,
}) => {
  const valueLeft = left + 25;

  return (
    <>
      <Content left={valueLeft}>
        <WrapperTitle>
          <Title onClick={() => changeService(data)}>{data?.title || data?.category_name}</Title>
        </WrapperTitle>
        <WrapperControl>
          <Control
            data={data}
            hiddenCopy={data?.type === 'category'}
            hiddenPlus={data?.type === 'service' || level >= 5}
            active={data?.is_active}
            setActive={() => changeActive(data)}
            setClickPencil={() => changeService(data)}
            setClickPlus={() => addServiceCategory(data, level)}
            setClickTrash={() => deleteService(data)}
            setClickCopy={() => copyService(data)}
          />
        </WrapperControl>
      </Content>
    </>
  );
};

const WrapperControl = styled.div`
  padding-right: 9px;
  opacity: 0;
`;
const WrapperTitle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const Title = styled.div`
  user-select: none;
  margin-left: 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black);
  &:hover {
    text-decoration: underline;
  }
`;
const Content = styled.div<{ left?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ left }) => {
    return css`
      padding: 10px 0 10px ${left}px;
    `;
  }}
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-grey-light);
  }
  &:hover {
    background: #f1f3f8;
  }
  &:hover ${WrapperControl} {
    opacity: 1;
  }
`;
