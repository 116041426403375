import React from 'react';
import styled, { css } from 'styled-components';
import { ItemSelectProsTree } from './index';

type ItemDataProps = {
  data: ItemSelectProsTree;
  onChange: (data: ItemSelectProsTree) => void;
  left?: number;
};

export const ItemData: React.FC<ItemDataProps> = ({ data, onChange, left = 10 }) => {
  return (
    <div>
      <Content left={left} onClick={() => onChange(data)}>
        <Active active={!!data?.active} />
        <Name>{data?.name}</Name>
      </Content>
      {!!data?.items?.length && (
        <div>
          {data?.items.map((item) => (
            <ItemData key={String(item?.id)} left={left + 5} data={item} onChange={onChange} />
          ))}
        </div>
      )}
    </div>
  );
};
const Name = styled.div`
  font-size: 14px;
  padding-left: 10px;
`;
const Active = styled.div<{ active?: boolean }>`
  border: 1px solid var(--color-purple);
  height: 10px;
  width: 10px;
  position: relative;

  ${({ active }) => {
    if (active) {
      return css`
        &:before {
          content: '';
          position: absolute;
          height: 5px;
          width: 5px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background: var(--color-purple);
        }
      `;
    } else {
      return css``;
    }
  }}
`;
const Content = styled.div<{ left: number }>`
  display: flex;
  align-items: center;
  padding: 1px 1px 1px 5px;
  cursor: pointer;
  &:hover {
    background: var(--color-grey);
  }
  ${({ left }) => {
    if (left) {
      return css`
        padding-left: ${left}px;
      `;
    }
  }}
`;
