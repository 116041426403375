import React from 'react';
import styled from 'styled-components';
import { Textarea } from 'components/textarea';
import { Checkbox } from 'components/checkbox';
import { DefaultButton, WhiteButton } from 'components/buttons';

const noop = () => undefined;
export const TypeMessage: React.FC = () => {
  return (
    <Content>
      <Row>
        <Block>
          <Title>Текст при успешной записи</Title>
          <Textarea />
        </Block>
        <WrapperCheckbox>
          <Checkbox active={true} setActive={noop} />
        </WrapperCheckbox>
      </Row>
      <Row>
        <Block>
          <Title>Успешная отмена записи</Title>
          <Textarea />
        </Block>
        <WrapperCheckbox>
          <Checkbox active={true} setActive={noop} />
        </WrapperCheckbox>
      </Row>
      <Row>
        <Block>
          <Title>Обратный звонок</Title>
          <Textarea />
        </Block>
        <WrapperCheckbox>
          <Checkbox active={true} setActive={noop} />
        </WrapperCheckbox>
      </Row>
      <Row>
        <Block>
          <Title>Обратный звонок</Title>
          <Textarea />
        </Block>
        <WrapperCheckbox>
          <Checkbox active={true} setActive={noop} />
        </WrapperCheckbox>
      </Row>
      <Row>
        <Block>
          <Title>Успешная оплата</Title>
          <Textarea />
        </Block>
        <WrapperCheckbox>
          <Checkbox active={true} setActive={noop} />
        </WrapperCheckbox>
      </Row>
      <WrapperButtons>
        <DefaultButton>Сохранить</DefaultButton>
        <WhiteButton>Отменить</WhiteButton>
      </WrapperButtons>
      <Dev><h4>В разработке</h4></Dev>
    </Content>
  );
};
const WrapperButtons = styled.div`
  margin-top: 100px;
  display: grid;
  grid-template-columns: 150px 150px;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
`;

const WrapperCheckbox = styled.div`
  padding-top: 50px;
`;
const Block = styled.div`
  textarea {
    height: 100px;
  }
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: var(--color-black);
  margin-bottom: 16px;
`;

const Row = styled.div`
  //height: 100%;
  display: grid;
  grid-template-columns: 450px 50px;
  grid-template-rows: 1fr;
  grid-column-gap: 40px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Content = styled.div`
  padding: 40px 60px;
  position: relative;
  pointer-events: none;
  opacity: 0.65;
`;

const Dev = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > h4 {
    text-align: center;
    opacity: 1;
    color: #461684;
    padding: 2em;
    background-color: rgba(255,255,255,0.8);
  }
`;