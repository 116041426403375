import React, { useState, useEffect } from 'react';
import { TimePicker } from 'components/timePicker';
import { FlexColumn, FlexRow, ValidationError } from '../styles';
import { compareTime } from '../utils';
import { DateTimePickersRowProps } from '../types';

export const TimePickersRow: React.FC<DateTimePickersRowProps> = (props) => {
  const {titleBegin, titleEnd, begin, end, disabled, showSuffix, setBegin, setEnd} = props;
  const [isValid, setValid] = useState<boolean>(true);
  
  useEffect(() => {
    if (begin !== undefined && end !== undefined) {
        setValid(compareTime(begin, end));  
    }
    return () => setValid(true);
  }, [begin, end]);

  useEffect(() => {
    !isValid && setEnd?.('');
  }, [isValid]);
  
  return (
    <FlexColumn>
      <FlexRow>
        <TimePicker
          title={titleBegin}
          value={begin}
          placeholder={'чч:мм'}
          height={32}
          disabled={disabled || false}
          showSuffix={showSuffix}
          valid={isValid}
          onChange={setBegin}
        />
        <div>&ndash;</div>
        <TimePicker
          title={titleEnd}
          value={end}
          placeholder={'чч:мм'}
          height={32}
          disabled={disabled || false}
          showSuffix={showSuffix}
          valid={isValid}
          onChange={setEnd}
        />
      </FlexRow>
      {!isValid && <ValidationError>Ошибка при выборе времени</ValidationError>}
    </FlexColumn>
  );
};
