import React from 'react';
import styled, { css } from 'styled-components';
import { WeekDaysItemProps } from '../types';

export const WeekDayItem: React.FC<WeekDaysItemProps> = ({id, name, active, colored}) => {
  return (
    <Content key={name} colored={colored}>
      {name}
    </Content>
  );
};

const Content = styled.div<{colored: boolean}>`
  width: 40px;
  height: 40px;
  padding: 9px;
  text-align: center;
  font-size: 13px;
  color: #6B6F76;
  ${({ colored }) => {
    if (colored) {
      return css`
        background-color: var(--color-blue);
        color: var(--color-white);
        border-radius: 50px;
        border: 1px #EFF1F4 solid;
      `;
    } else {
      return css`
        background-color: var(--color-white);
      `;
    }
  }};
`;
