import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { timeData } from './lib';
import { ButtonTime } from 'components/buttons';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { setTime, entrySelector } from 'features/entry/entrySlice';

type TimeEntryProps = {
  setShowCalendar: (show: boolean) => void;
  changeTime: (value?: string) => void;
  schedule: Record<string, string | number | null>[] | null;
};

export const TimeEntry: React.FC<TimeEntryProps> = ({ setShowCalendar, changeTime, schedule }) => {
  const dispatch = useAppDispatch();
  const date_at = useAppSelector(entrySelector.date_at);
  const time_at = useAppSelector(entrySelector.time_at);
  const [sheduledDates, setSheduledDates] = useState<Record<string, string | number | null>[]>([]);
  const handleSetTime = (e: any, time: string) => {    
      dispatch(setTime(time));
      changeTime(time);
      document.querySelectorAll('.time-selected').forEach(item => item.classList.remove('time-selected'));
      e.target.classList.add('time-selected');
  };

  useEffect(() => {
    if (schedule) {
      setSheduledDates(schedule.filter(item => item.date_at === date_at));
    }
  }, [schedule, date_at]);

  const isTimeDisabled = (time: string) => 
    sheduledDates.filter((s: any) => s.time_at === time).length ? true : false;
  
  return (
    <Content>
      {timeData.map((item) => (
        <ScheduleButtonTime 
          key={item.id} 
          onClick={(e: any) => handleSetTime(e,item.label)} 
          onDoubleClick={() => setShowCalendar(false)}
          disabled={isTimeDisabled(item.label) || false}
          className={item.label === time_at ? 'time-selected' : ''}
        >{item.label}</ScheduleButtonTime>
      ))}
    </Content>
  );
};

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
  grid-template-rows: repeat(44px/9);
  padding: 0 5px;
  max-height: 180px;
  overflow-y: auto;
`;

const ScheduleButtonTime = styled(ButtonTime)<{ disabled: boolean }>`
  border: 1px solid #dfe1e4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-black);
  font-size: 14px;
  font-weight: 400;
  padding: 10px 0;
  cursor: pointer;
  &:hover,
  &.time-selected {
    color: var(--color-blue);
    border-color: var(--color-blue);
  }
  ${({ disabled }) => {
    if (disabled) {
      return css`
        pointer-events:none;
        opacity: .85;
        color: var(--color-table-title);
        border-color: var(--color-grey);
        background-color: var(--color-grey-light);
        &.time-selected {
          color: var(--color-red);
          border-color: var(--color-red);
        }
      `;
    }
  }}
`;