import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { IconPlus, IconSearch } from 'icons';
import { Input } from 'components/input';
import { CheckboxService } from 'components/checkboxService';
import { InfoPopUp } from 'components/infoPopUp';

import debounce from 'lodash/debounce';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { specialistSelector, specialistSlice } from '../../specialistSlice';
import { InputSearch, ItemDataSearchProps } from 'components/ui/inputSearch';
import { transformDataSearch } from '../../utils';
import { changeActiveConnectData, changeActiveDataSearch } from 'components/ui/inputSearch/utils';
import { useTabsContext } from '../context';

type ServiceProps = {
  setChanged: (isChanged: boolean) => void;
};

export const Service: React.FC<ServiceProps> = ({ setChanged }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const searchData = useAppSelector(specialistSelector.search);
  const [search, setSearch] = useState<string>('');
  const [checkbox, setCheckbox] = useState(false);

  const [dataSearch, setDataSearch] = useState<ItemDataSearchProps[]>([]);
  const { state: stateTabs, setState: setStateTabs } = useTabsContext();

  const activeServices = useMemo(() => stateTabs?.person?.data?.services || [], [stateTabs?.person?.data?.services]);
  const data = useMemo(() => stateTabs?.person?.data, [stateTabs?.person?.data]);

  useEffect(() => {
    if (!search) return;

    const activeIds = stateTabs?.person?.data?.services?.map((i: Record<string, any>) => i.id);

    const data = transformDataSearch(searchData, activeIds);

    setDataSearch(data);
  }, [searchData]);

  const showValue = (search: string) => {
    setLoading(true);
    dispatch(
      specialistSlice.actions.searchServiceRequest({
        payload: {
          data: {
            title: search || null,
          },
          success: () => setLoading(false),
        },
      }),
    );
    if (!search) {
      setDataSearch([]);
    }
  };

  const handledDebounce = useCallback(debounce(showValue, 500), []);

  const handledOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(!!e.target.value);
    setSearch(e.target.value);
    handledDebounce(e.target.value);
  };

  const handledChangeDataSearch = (id?: number, item?: ItemDataSearchProps, data?: ItemDataSearchProps[]) => {
    if (id) {
      const nextData = changeActiveDataSearch(id, dataSearch);
      setDataSearch(nextData);

      if (item) {
        const nextActiveSearch = changeActiveConnectData(item, activeServices);

        setStateTabs?.((prev) => ({
          ...prev,
          person: {
            ...prev?.person,
            data: {
              ...prev?.person?.data,
              services: nextActiveSearch,
            },
          },
        }));
        setChanged(true);
      }
    }
  };

  const handledChangeItemService = (id?: number, item?: ItemDataSearchProps) => () => {
    if (item) {
      const nextActiveSearch = changeActiveConnectData(item, activeServices);
      setStateTabs?.((prev) => ({
        ...prev,
        person: {
          ...prev?.person,
          data: {
            ...prev?.person?.data,
            services: nextActiveSearch,
          },
        },
      }));
      setChanged(true);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setStateTabs?.((prev) => ({
      ...prev,
      person: {
        ...prev?.person,
        data: {
          ...prev?.person?.data,
          [e.target.name]: e.target.value,
        },
      },
    }));
    setChanged(true);
  };

  return (
    <Content>
      <Left>
        <Head>
          <Title>Услуги</Title>
          {/*<WrapperIconPlus>*/}
          {/*  <IconPlus />*/}
          {/*</WrapperIconPlus>*/}
        </Head>
        <WrapperInput>
          <InputSearch
            onFocus={() => setLoading(true)}
            onBlur={() => setLoading(false)}
            loading={loading}
            icon={IconSearch}
            placeholder={'Найти услугу'}
            value={search}
            onChange={handledOnChangeSearch}
            data={dataSearch}
            onChangeData={handledChangeDataSearch}
          />
        </WrapperInput>
        {activeServices?.length ? (
          activeServices?.map((item: ItemDataSearchProps) => (
            <ActiveItemService key={item.id}>
              <CheckboxService
                active={item.active}
                setActive={handledChangeItemService(item.id, { ...item, active: !item.active })}
                title={item.label}
              />
            </ActiveItemService>
          ))
        ) : (
          <TextData>Добавьте услуги используя Поиск</TextData>
        )}
      </Left>
      <Right>
        <TitleRight>Профессия специалиста</TitleRight>
        <WrapperInput>
          <Input
            placeholder={'Введите профессию'}
            name='profession'
            value={data?.profession}
            onChange={handleOnChange}
          />
        </WrapperInput>
        {/*<TitleRight>Совмещение</TitleRight>*/}
        {/*<BlockCheckbox>*/}
        {/*  <CheckboxService*/}
        {/*    active={checkbox}*/}
        {/*    setActive={() => setCheckbox((prev) => !prev)}*/}
        {/*    title={'Совмещение параллельных заказов'}*/}
        {/*  />*/}
        {/*  <WrapperInfo>*/}
        {/*    <InfoPopUp>*/}
        {/*      <TextInfo>Текст подсказки</TextInfo>*/}
        {/*    </InfoPopUp>*/}
        {/*  </WrapperInfo>*/}
        {/*</BlockCheckbox>*/}
      </Right>
    </Content>
  );
};
const TextData = styled.div`
  font-size: 14px;
  text-align: center;
  padding: 10px;
`;
const ActiveItemService = styled.div`
  padding: 10px;

  &:hover {
    background: rgba(231, 231, 231, 0.56);
  }

  &:not(:last-child) {
    border-bottom: 2px solid var(--color-grey);
  }
`;
const TextInfo = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
`;
const WrapperInfo = styled.div`
  margin-left: 16px;
`;
const BlockCheckbox = styled.div`
  display: flex;
  align-items: center;
`;
const Left = styled.div`
  width: 378px;
`;
const Right = styled.div`
  width: 378px;
  margin-left: 56px;
`;
const WrapperInput = styled.div`
  margin-bottom: 24px;
`;
const WrapperIconPlus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    path {
      fill: #6471e0;
    }
  }
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
`;
const TitleRight = styled(Title)`
  margin-bottom: 16px;
`;
const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
`;
const Content = styled.div`
  display: flex;
  padding: 24px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 8px;
  min-width: 1164px;
`;
