import createRequestRest from 'api/createRequestRest';
import { put } from 'redux-saga/effects';
import { serviceSlice } from '../serviceSlice';

function* prepareRequest(payload: any) {
  return payload?.payload?.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(serviceSlice.actions.getSearchServiceSuccess(response));
  payload?.payload?.success?.();
}

function* prepareFailure(res: any) {
  yield;
}

export const getSearchService = () => {
  return createRequestRest({
    url: '/api/v1/service/search',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: serviceSlice.actions.getSearchServiceRequest,
    method: 'get',
  });
};
