import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Header } from './header';
import { Moment } from 'moment';
import { Row } from './row';

const dataTime = [
  '00:00',
  '1:00',
  '2:00',
  '3:00',
  '4:00',
  '5:00',
  '6:00',
  '7:00',
  '8:00',
  '9:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
  '00:00',
];

type TableProps = {
  dates: Moment[];
  updateData: () => void;
};

export const Table: React.FC<TableProps> = ({ dates, updateData }) => {
  const refBody = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refBody.current) {
      refBody.current.scrollTo(0, 350);
    }
  }, [refBody.current]);

  return (
    <Content>
      <Header date={dates} />
      <Body ref={refBody}>
        {dataTime.map((time, index) =>
          index < dataTime.length - 1 ? (
            <Row key={time} index={index} time={time} dates={dates} updateData={updateData} />
          ) : null,
        )}
      </Body>
    </Content>
  );
};
const Body = styled.div`
  padding-top: 5px;
  height: calc(100vh - 96px);
  width: calc(100vw - 220px);
  overflow: auto;
  padding-bottom: 100px;
`;
const Content = styled.div``;
