import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { IconArrowLeftFilter, IconFilter } from 'icons';
import { ContentPopUpList, ItemContentPopUpList, TitleFilter } from '../style';
import { useMouseDown } from 'core/hooks';
import { changeActiveFilterComplex, countFilterComplex } from './utils';
import { Checkbox } from '../checkbox';

export type FilterDataComplexProps = {
  id: number;
  active: boolean;
  value: string | number;
};

export type FilterParentComplexProps = {
  id: number;
  value: string | number;
  active: boolean;
  items: FilterDataComplexProps[];
};

type FilterComplexProps = {
  data: FilterParentComplexProps[];
  onChange: (data: FilterParentComplexProps[]) => void;
};

export const FilterComplex: React.FC<FilterComplexProps> = ({ data, onChange }) => {
  const listRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const [counts, setCounts] = useState<number>(0);
  const [itemFilter, setItemFilter] = useState<FilterParentComplexProps>();
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    if (itemFilter) {
      const item = data.find((i) => i.id === itemFilter.id);
      item && setItemFilter(item);
    }
  }, [data]);
  useMouseDown(() => setShowList(false), listRef, inputRef);

  const handleClickSorting = () => {
    setShowList((prev) => !prev);
  };

  const handleClickItems = (item: FilterParentComplexProps) => () => {
    setItemFilter(item);
  };

  const handleClickChecked = (id: number) => () => {
    if (itemFilter) {
      onChange(changeActiveFilterComplex(data, itemFilter, id));
      setCounts(countFilterComplex(changeActiveFilterComplex(data, itemFilter, id)));
    }
  };
  return (
    <Content active={showList}>
      {/*<Header ref={inputRef} onClick={handleClickSorting}>
        <WrapperIcon>
          <IconFilter />
        </WrapperIcon>
        <TitleFilter>Фильтр</TitleFilter>
        {!!counts && <CountFilter>{counts}</CountFilter>}
      </Header>
      <ContentPopUpList ref={listRef} active={showList} padding={!!itemFilter?.items?.length}>
        {itemFilter?.items?.length ? (
          <ItemsBlock>
            <WrapperValueItems>
              <WrapperIconItems onClick={() => setItemFilter(undefined)}>
                <IconArrowLeftFilter />
              </WrapperIconItems>
              <ValueItems>{itemFilter.value}</ValueItems>
            </WrapperValueItems>
            {itemFilter?.items?.map((item) => (
              <WrapperItemItems key={item.id}>
                <Checkbox
                  color={'#DFE1E4'}
                  title={item.value.toString()}
                  active={item.active}
                  setActive={handleClickChecked(item.id)}
                />
              </WrapperItemItems>
            ))}
          </ItemsBlock>
        ) : (
          data?.map((item) => (
            <ItemContentPopUpList key={item.id} onClick={handleClickItems(item)}>
              {item.value}
            </ItemContentPopUpList>
          ))
        )}
      </ContentPopUpList>*/}
    </Content>
  );
};
const WrapperItemItems = styled.div`
  padding: 7px 12px;
`;
const WrapperValueItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 4px 10px 12px;
  border-bottom: 1px solid #dfe1e4;
`;
const WrapperIconItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
`;
const ValueItems = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #282a2f;
`;
const ItemsBlock = styled.div``;
const CountFilter = styled.div`
  height: 18px;
  width: 18px;
  background: var(--color-blue);
  color: var(--color-white);
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
`;
const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;
const Header = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 26px;
`;
const Content = styled.div<{ active: boolean }>`
  width: 100%;
  position: relative;
  padding: 8px 10px;
  border-radius: 4px;
  &:hover {
    background: #f0f3f9;
  }
  ${({ active }) =>
    active
      ? css`
          background: #f0f3f9;
        `
      : null}
`;
