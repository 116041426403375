export const routingApi = {
  auth: {
    sign_in: '/sign_in',
    sign_up: '/sign_up',
  },
  crm: {
    link: '/',
    path: '/',
  },
  schedule: {
    link: '/schedule',
    path: '/schedule',
  },
  data: {
    link: '/data/*',
    path: '/data/*',
    specialists: {
      link: '/data/specialists',
      path: '/specialists',
    },
    workplace: {
      link: '/data/workplace',
      path: '/workplace',
    },
    services: {
      link: '/data/services',
      path: '/services',
    },
    branches: {
      link: '/data/branches',
      path: '/branches',
    },
    catalog: {
      link: '/data/catalog',
      path: '/catalog',
    },
    todolist: {
      link: '/data/todolist',
      path: '/todolist',
    },
  },
  clientBase: {
    link: '/client_base',
    path: '/client_base',
  },
  stock: {
    link: '/stock',
    path: '/stock/*',
    setting: {
      link: '/stock/setting',
      path: '/setting',
    },
    story: {
      link: '/stock/story',
      path: '/story',
    },
  },
  messages: {
    link: '/messages',
    path: '/messages/*',
    setting: {
      link: '/messages/setting',
      path: '/setting',
    },
    story: {
      link: '/messages/story',
      path: '/story',
    },
  },
  appointment: {
    link: '/appointment',
    path: '/appointment/*',
    setting: {
      link: '/appointment/setting',
      path: '/setting',
    },
    priority: {
      link: '/appointment/priority',
      path: '/priority',
    },
  },
  site: {
    link: '/site',
    path: '/site',
  },
  general: {
    link: '/general',
    path: '/general/*',
    window: {
      link: '/general/window',
      path: '/window',
    },
    card: {
      link: '/general/card',
      path: '/card',
    },
    templates: {
      link: '/general/templates',
      path: '/templates',
    },
  },
  account: {
    link: '/account',
    path: '/account/*',
    finance: {
      link: '/account/finance',
      path: '/finance',
    },
    help: {
      link: '/account/help',
      path: '/help',
    },
  },
};
