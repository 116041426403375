import React from 'react';
import { ScheduleRow, ButtonAddRow } from '../row';
import { ScheduleVocationProps } from '../types';
import { ScheduleContainer, ScheduleRowTitle, ScheduleRowBlock } from '../styles';

export const ScheduleVocation: React.FC<ScheduleVocationProps> = ({vocations, addVocation, removeVocation, setChanged}) => {
  return (
    <>
      <ScheduleContainer>
        <ScheduleRowBlock>
          <ScheduleRowTitle>Отпуск</ScheduleRowTitle>
          {vocations.map((i: number) => (
            <ScheduleRow 
              key={i}
              type={'date'}
              number={i}
              itemsCount={vocations?.length}
              disabled={false}
              showSuffix={true}
              titleBegin='Дата начала'
              titleEnd='Дата окончания'
              setChanged={setChanged}
              onDelete={removeVocation}
            />
          ))}
        </ScheduleRowBlock>
      </ScheduleContainer>
      <ButtonAddRow onAdd={addVocation} title='Добавить' />
    </>
  );
};
