import React from 'react';
import styled from 'styled-components';
import { Input } from 'components/input';
import { Checkbox } from 'components/checkbox';
import { DefaultButton, WhiteButton } from 'components/buttons';

export const ContactsRecords: React.FC = () => {
  const noop = () => undefined;
  return (
    <Content>
      <WrapperBlock>
        <Block>
          <WrapperRow>
            <Title>Поле</Title>
            <Title>Обязательная запись</Title>
          </WrapperRow>
          <WrapperRow>
            <Input placeholder='Имя' name='name' />
            <Checkbox active={true} setActive={noop} />
          </WrapperRow>
          <WrapperRow>
            <Input placeholder='Телефон' />
            <Checkbox active={true} setActive={noop} />
          </WrapperRow>
          <WrapperRow>
            <Input placeholder='Номер машины' />
            <Checkbox active={true} setActive={noop} />
          </WrapperRow>
          <WrapperRow>
            <Input placeholder='Год выпуска' />
            <Checkbox active={true} setActive={noop} />
          </WrapperRow>
          <WrapperRow>
            <Input placeholder='Дата рождения' />
            <Checkbox active={true} setActive={noop} />
          </WrapperRow>
          <WrapperRow>
            <Input placeholder='Подарочный сертификат' />
            <Checkbox active={true} setActive={noop} />
          </WrapperRow>
        </Block>
        <Block>
          <WrapperRow>
            <Title>Данные о машине</Title>
            <Title>Обязательная запись</Title>
          </WrapperRow>
          <WrapperRow>
            <Input placeholder='Марка' />
            <Checkbox active={true} setActive={noop} />
          </WrapperRow>
          <WrapperRow>
            <Input placeholder='Модель' />
            <Checkbox active={true} setActive={noop} />
          </WrapperRow>
          <WrapperRow>
            <Input placeholder='Номер машины' />
            <Checkbox active={true} setActive={noop} />
          </WrapperRow>
          <WrapperRow>
            <Input placeholder='Год выпуска' />
            <Checkbox active={true} setActive={noop} />
          </WrapperRow>
          <WrapperRow>
            <Input placeholder='Цвет' />
            <Checkbox active={true} setActive={noop} />
          </WrapperRow>
        </Block>
        <Dev><h4>В разработке</h4></Dev>
      </WrapperBlock>
      <WrapperButtons>
        <DefaultButton>Сохранить</DefaultButton>
        <WhiteButton>Отменить</WhiteButton>
      </WrapperButtons>
    </Content>
  );
};
const WrapperBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
const WrapperButtons = styled.div`
  margin-top: 100px;
  display: grid;
  grid-template-columns: 150px 150px;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
`;
const Title = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: var(--color-black);
`;
const WrapperRow = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-column-gap: 32px;
  grid-template-columns: 1fr 100px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Block = styled.div`
  width: 100%;
`;

const Content = styled.div`
  padding: 45px 60px;
  position: relative;
  pointer-events: none;
  opacity: 0.65;
`;

const Dev = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > h4 {
    text-align: center;
    opacity: 1;
    color: #461684;
    padding: 2em;
    background-color: rgba(255,255,255,0.8);
  }
`;
