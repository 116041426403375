import { DataSort } from 'components/filters/sorting';
import { FilterParentComplexProps } from 'components/filters/filterComplex';
import { KartProps } from './types';

export const removeFromColumn = (column: KartProps[], index: number): [KartProps, KartProps[]] => {
  const result: KartProps[] = Array.from(column);
  const [removed] = result.splice(index, 1);
  return [removed, result];
};

export const addToColumn = (column: KartProps[], index: number, element: KartProps): KartProps[] => {
  const result: KartProps[] = Array.from(column);
  result.splice(index, 0, element);
  return result;
};

export const initFilters: FilterParentComplexProps[] = [
  {
    id: 1,
    value: 'Специалист',
    active: false,
    items: [
      {
        id: 11,
        value: 'Специалист 0',
        active: false,
      },
      {
        id: 12,
        value: 'Специалист 1',
        active: false,
      },
      {
        id: 13,
        value: 'Специалист 2',
        active: false,
      },
    ],
  },
  {
    id: 2,
    value: 'Кабинет',
    active: false,
    items: [
      {
        id: 21,
        value: 'Кабинет 0',
        active: false,
      },
      {
        id: 22,
        value: 'Кабинет 1',
        active: false,
      },
      {
        id: 23,
        value: 'Кабинет 2',
        active: false,
      },
    ],
  },
];

export const dataSorting: any[] = [
  {
    id: 0,
    value: 'По времени',
  },
  {
    id: 1,
    value: 'По приоритету',
  },
];
export const dataSortingOrders: any[] = [
  {
    id: 0,
    value: 'Заказы',
  },
  {
    id: 1,
    value: 'Записи',
  },
  {
    id: 1,
    value: 'Записи и заказы',
  },
];
export const dataSortingCalendar: any[] = [
  {
    id: 0,
    value: 'День',
  },
  {
    id: 1,
    value: 'Неделя',
  },
  {
    id: 1,
    value: 'Месяц',
  },
];
