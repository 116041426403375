import { all } from 'redux-saga/effects';
import { getClientsAll } from './getClientsAll';
import { postCreateClients } from './postCreateClients';
import { getClientId } from './getClientId';
import { postUpdateClients } from './postUpdateClients';
import { deleteClient } from './deleteClient';

export default function* clientSaga() {
  yield all([getClientsAll(), postCreateClients(), getClientId(), postUpdateClients(), deleteClient()]);
}
