import { WeekDaysItemProps, ScheduleBranchState } from './types';
import moment from 'moment';

export const weekDays: WeekDaysItemProps[] = [
    { id: 1, name: 'Пн', active: true, colored: true},
    { id: 2, name: 'Вт', active: true, colored: false},
    { id: 3, name: 'Ср', active: true, colored: false},
    { id: 4, name: 'Чт', active: true, colored: false},
    { id: 5, name: 'Пт', active: true, colored: true},
    { id: 6, name: 'Сб', active: false, colored: false},
    { id: 7, name: 'Вс', active: false, colored: false},
];

export const scheduleTypes: Record<string, Record<string, string>> = {
    byWeekDays: {
        title: 'По дням недели',
        type: 'by_week_days'
    },
    byShifts: {
        title: 'По сменам',
        type: 'by_shifts'
    },
};

export const calendarTypes: string[] = ['По дням недели','По календарю'];

export const mockScheduleItems: Record<string, any>[] = [
    {
        month: 10,
        day: 28,
        year: 2024,
        week: null,
        data: [
            {
                name: 'Кузнецов А.В.',
                grafik: {
                    from: "10:00",
                    to: "19:00"
                },
                timeout: [
                    {
                        from: "13:00",
                        to: "14:00"
                    }
                ]
            },
        ]
    },
    {
        month: 10,
        day: 29,
        year: 2024,
        week: null,
        data: [
            {
                name: 'Кузнецов А.В.',
                grafik: {
                    from: "10:00",
                    to: "19:00"
                },
                timeout: [
                    {
                        from: "13:00",
                        to: "14:00"
                    }
                ]
            }
        ]
    },
    {
        month: 11,
        day: 1,
        year: 2024,
        week: null,
        data: [
            {
                name: 'Иванова Е.И.',
                grafik: {
                    from: "10:00",
                    to: "14:30"
                },
                timeout: [
                    {
                        from: "12:00",
                        to: "13:00"
                    }
                ]
            },
            {
                name: 'Ильин С.П.',
                grafik: {
                    from: "14:00",
                    to: "16:30"
                },
                timeout: []
            },
            {
                name: 'Кузнецов А.В.',
                grafik: {
                    from: "16:30",
                    to: "18:00"
                },
                timeout: []
            }
        ]
    },
    {
        month: 11,
        day: 2,
        year: 2024,
        week: null,
        data: [
            {
                name: 'Кузнецов А.В.',
                grafik: {
                    from: "10:00",
                    to: "18:00"
                },
                timeout: [
                    {
                        from: "13:30",
                        to: "14:00"
                    },
                    {
                        from: "16:30",
                        to: "17:00"
                    }
                ]
            }
        ]
    },
    {
        month: 11,
        day: 5,
        year: 2024,
        week: null,
        data: [
            {
                name: 'Кузнецов А.В.',
                grafik: {
                    from: "10:00",
                    to: "18:00"
                },
                timeout: [
                    {
                        from: "13:30",
                        to: "14:00"
                    },
                    {
                        from: "16:30",
                        to: "17:00"
                    }
                ]
            }
        ]
    },
    {
        month: 11,
        day: 6,
        year: 2024,
        week: null,
        data: [
            {
                name: 'Ильин С.П.',
                grafik: {
                    from: "10:00",
                    to: "15:00"
                },
                timeout: [
                    {
                        from: "13:30",
                        to: "14:00"
                    }
                ]
            },
            {
                name: 'Кузнецов А.В.',
                grafik: {
                    from: "15:00",
                    to: "18:00"
                },
                timeout: [
                    {
                        from: "16:30",
                        to: "17:00"
                    }
                ]
            }
        ]
    },
];

export const getMockScheduleItems = (id: number | null) => {
    if (id) {
        return {
            id: id,
            schedule: JSON.stringify(mockScheduleItems)
        };
    }
    return {};
};

export const mockSheduleSpecialists = [
    {
        name: 'Кузнецов А.В.',
        grafik: {
            from: "09:00",
            to: "18:00"
        },
        timeout: [
            {
                from: "13:00",
                to: "14:00"
            }
        ]
    },
    {
        name: 'Иванова Е.И.',
        grafik: {
            from: "09:00",
            to: "18:00"
        },
        timeout: [
            {
                from: "13:00",
                to: "14:00"
            }
        ]
    },
    {
        name: 'Ильин С.П.',
        grafik: {
            from: "09:00",
            to: "18:00"
        },
        timeout: [
            {
                from: "12:00",
                to: "13:00"
            }
        ]
    },
];

export const initialState: ScheduleBranchState = {
    data: [
        {
            branch_id: null,
            schedule: weekDays.map(d => {
                return {
                    day: +moment().day(d.id).format('DD'),
                    month: +moment().day(d.id).format('MM'),
                    year: +moment().day(d.id).format('YYYY'),
                    week: d.id,
                    active: d.active,
                    data: [
                        {
                            name: null,
                            branch_id: null,
                            workplace_id: null,
                            specialist_id: null,
                            grafik: {
                                from: "10:00",
                                to: "19:00"
                            },
                            timeout: [
                                {
                                    from: "13:00",
                                    to: "14:00"
                                }
                            ]
                        },
                    ]
                };
            }).sort((a, b) => a.week - b.week )
        }
    ],
};