import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TimePickersRow } from './timePickersRow';
import { DatePickersRow } from './datePickersRow';
import { ButtonAddRow } from './buttonAddRow';
import { ButtonRemoveRow } from './buttonRemoveRow';
import { ScheduleRowProps } from '../types';

const ScheduleRow: React.FC<ScheduleRowProps> = (props) => {
  const {type, number, itemsCount, titleBegin, titleEnd, disabled, showSuffix, setChanged, onDelete} = props;
  const [timeBegin, setTimeBegin] = useState<string>();
  const [timeEnd, setTimeEnd] = useState<string>();
  const [dateBegin, setDateBegin] = useState<string | undefined>();
  const [dateEnd, setDateEnd] = useState<string | undefined>();

  /*const handleSetTimeBegin = () => {}
  const handleSetTimeEnd = () => {}
  const handleSetDateBegin = () => {}
  const handleSetDateEnd = () => {}*/

  useEffect(() => {
    if (timeBegin || timeEnd || dateBegin || dateEnd) {
      setChanged?.(true);
    }
  }, [timeBegin,timeEnd,dateBegin,dateEnd]);
  
  return (
    <Content>
      <InputsBlock>
        {type === 'time' && 
          <TimePickersRow 
            titleBegin={titleBegin}
            titleEnd={titleEnd}
            begin={timeBegin}
            end={timeEnd}
            disabled={disabled || false}
            showSuffix={showSuffix}
            setBegin={setTimeBegin}
            setEnd={setTimeEnd}
          />
        }
        {type === 'date' && 
          <DatePickersRow 
            titleBegin={titleBegin}
            titleEnd={titleEnd}
            begin={dateBegin}
            end={dateEnd}
            disabled={disabled || false}
            showSuffix={showSuffix}
            setBegin={setDateBegin}
            setEnd={setDateEnd}
          />
        }
        {itemsCount && itemsCount > 1 && <ButtonRemoveRow number={number} onDelete={onDelete} />}
      </InputsBlock>
    </Content>
  );
};

export {ScheduleRow, ButtonAddRow};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
`;
const InputsBlock = styled.div`
  display: flex!important;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  & *:nth-child(1) {flex: 0 1 auto;}
  & *:nth-child(2) {flex: 0 1 3%;text-align: center;}
  & *:nth-child(3) {flex: 0 1 auto;}
  & *:nth-child(4) {flex: 0 1 5%;}
  & input[type=time]:disabled {color: var(--color-white);}
  & input[type=date]:disabled {color: var(--color-white);}
`;