import React from 'react';
import styled from 'styled-components';
import { ItemTitleHead } from './itemTitleHead';
import { Moment } from 'moment';

type HeaderProps = {
  date: Moment[];
};

export const HeaderTable: React.FC<HeaderProps> = ({ date }) => {
  return (
    <Content>
      <ItemTitle>Специалист</ItemTitle>
      {date?.map((date, index) => (
        <ItemTitleHead key={date.toString()} date={date} index={index} />
      ))}
    </Content>
  );
};

const Content = styled.div`
  display: grid;
  grid-template-columns: 265px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid #eff1f4;
  padding-bottom: 5px;
`;
const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  padding-left: 24px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #82889a;
`;
