import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { AddPhoto } from 'features/addPhoto';
import { Input } from 'components/input';
import { Textarea } from 'components/textarea';
import { Switch } from 'components/switch';
import { RadioInput } from 'components/radioInput';
import { useTabsContext } from './context';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import {
  categorySelectorServiceSelector,
  serviceActiveCategorySelector,
  serviceSlice,
  servicesServiceSelector,
} from '../serviceSlice';
import { transformDataSelectorService } from 'components/selectorStaticTree/utils';
import { SelectorStaticTree } from 'components/selectorStaticTree';
import { TimePicker } from 'components/timePicker';

const initValidData: Record<string, boolean> = {
  title: true,
};

type ServiceTabProps = {
  setChanged: (isChanged: boolean) => void;
};

export const ServiceTab: React.FC<ServiceTabProps> = ({ setChanged }) => {
  const priceRef = useRef<{ from?: string; to?: string }>({ from: '', to: '' });
  const dispatch = useAppDispatch();
  const serviceActive = useAppSelector(serviceActiveCategorySelector);
  const categorySelector = useAppSelector(categorySelectorServiceSelector);
  const dataSelector = transformDataSelectorService(categorySelector);

  const serviceSelector = useAppSelector(servicesServiceSelector);
  const dataServiceSelector = transformDataSelectorService(serviceSelector);

  const [validData, setValidData] = useState(initValidData);
  const [radio, setRadio] = useState('fix_sum');

  const { state: stateTabs, setState: setStateTabs } = useTabsContext();

  const data = useMemo(() => stateTabs?.service?.data, [stateTabs?.service?.data]);

  const handledChangeRadio = (value: string) => {
    if (value === 'fix_sum') {
      priceRef.current = { from: '', to: '' };
      setStateTabs?.((prev) => ({
        ...prev,
        service: {
          ...prev?.service,
          data: {
            ...prev?.service?.data,
            price_dynamic: null,
          },
        },
      }));
    } else {
      setStateTabs?.((prev) => ({
        ...prev,
        service: {
          ...prev?.service,
          data: {
            ...prev?.service?.data,
            price_fixed: null,
          },
        },
      }));
    }
    setRadio(value);
    setChanged(true);
  };

  useEffect(() => {
    if (data?.price_fixed) {
      setRadio('fix_sum');
    } else {
      setRadio('indefinite_sum');
    }
  }, [data?.price_fixed]);

  useEffect(() => {
    if (serviceActive) {
      setStateTabs?.((prev) => ({
        ...prev,
        service: {
          ...prev?.service,
          data: {
            ...prev?.service?.data,
            ...serviceActive,
          },
          activeTab: 'service',
        },
      }));
    }
  }, [serviceActive]);

  useEffect(() => {
    dispatch(serviceSlice.actions.getCategorySelectorRequest());
    return () => {
      setStateTabs?.((prev) => ({
        ...prev,
        service: {
          ...prev?.service,
          activeTab: null,
        },
      }));
    };
  }, []);

  useEffect(() => {
    setStateTabs?.((prev) => ({
      ...prev,
      service: {
        ...prev?.service,
        activeTab: 'service',
        onIsValid: () => {
          const newValid = Object.entries(validData).reduce(
            (acc, [key, _]) => ({ ...acc, [key]: !!prev?.service?.data?.[key] }),
            {},
          );
          setValidData(newValid);
          return !Object.entries(newValid)
            .map(([_, item]) => item)
            .includes(false);
        },
      },
    }));
  }, [validData, setValidData, data]);

  const handleChangePhoto = (file: Record<string, any>) => {
    setStateTabs?.((prev) => ({
      ...prev,
      service: { ...prev?.service, data: { ...prev?.service?.data, image: file } },
    }));
    setChanged(true);
  };

  const handleOnChangeTime = (name: string) => (value?: string) => {
    setValidData((prev) => ({ ...prev, [name]: true }));
    setStateTabs?.((prev) => ({
      ...prev,
      service: { ...prev?.service, data: { ...prev?.service?.data, [name]: value } },
    }));
    setChanged(true);
  };
  const handleChangeData = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValidData((prev) => ({ ...prev, [e.target.name]: true }));
    setStateTabs?.((prev) => ({
      ...prev,
      service: { ...prev?.service, data: { ...prev?.service?.data, [e.target.name]: e.target.value } },
    }));
    setChanged(true);
  };
  const handleChangeParentSelector = (id: number | symbol | null) => {
    setValidData((prev) => ({ ...prev, parent_id: !!id }));
    setStateTabs?.((prev) => ({
      ...prev,
      service: {
        ...prev?.service,
        prevCategoryId: serviceActive?.parent_id,
        data: {
          ...prev?.service?.data,
          parent_id: id,
        },
      },
    }));
    setChanged(true);
  };
  // const handleChangeSelector = (id: number | symbol | null) => {
  //   setValidData((prev) => ({ ...prev, category_id: !!id }));
  //   setStateTabs?.((prev) => ({
  //     ...prev,
  //     service: {
  //       ...prev?.service,
  //       prevCategoryId: serviceActive?.category_id,
  //       data: {
  //         ...prev?.service?.data,
  //         category_id: id,
  //       },
  //     },
  //   }));
  // };
  const handleClickPriceDynamic = (fromValue = '', toValue = '') => {
    if (fromValue) {
      priceRef.current = { ...priceRef.current, from: fromValue };
    } else {
      priceRef.current = { ...priceRef.current, to: toValue };
    }
    const { from, to } = priceRef.current;
    const price_dynamic = [from, to].filter(Boolean).join(';');

    const nextDataPriceDynamic = price_dynamic.split(';').length ? price_dynamic : undefined;
    setStateTabs?.((prev) => ({
      ...prev,
      service: { ...prev?.service, data: { ...prev?.service?.data, price_dynamic: nextDataPriceDynamic } },
    }));
    setChanged(true);
  };
  const priceDynamicFrom = useMemo(() => {
    if (data?.price_dynamic) {
      const value = data?.price_dynamic.split(';');
      if (value?.length >= 1 && value?.[0] !== 'undefined') {
        return data?.price_dynamic.split(';')[0];
      }
    }
    return '';
  }, [data?.price_dynamic]);

  const priceDynamicTo = useMemo(() => {
    if (data?.price_dynamic) {
      const value = data?.price_dynamic.split(';');
      if (value?.length === 2) {
        return data?.price_dynamic.split(';')[1];
      }
    }
    return '';
  }, [data?.price_dynamic]);

  return (
    <>
      <BlockModal>
        <BlockHead>
          <WrapperAddPhoto>
            <AddPhoto onChange={handleChangePhoto} urlImg={data?.image?.file_path} />
          </WrapperAddPhoto>
          <BlockRight>
            <Input
              placeholder='Название услуги'
              value={data?.title || ''}
              name='title'
              onChange={handleChangeData}
              valid={validData.title}
            />
            {/* <WrapperSelector>
              <SelectorStaticTree
                isClear
                data={dataSelector}
                activeId={+data?.category_id}
                placeholder='Категория'
                onChange={handleChangeSelector}
              />
            </WrapperSelector> */}
            <WrapperSelector>
              <SelectorStaticTree
                isClear
                data={dataServiceSelector}
                activeId={+data?.parent_id}
                placeholder='Родительская услуга'
                onChange={handleChangeParentSelector}
                valid={validData.parent_id}
              />
            </WrapperSelector>
          </BlockRight>
        </BlockHead>
      </BlockModal>
      <BlockModal>
        <Textarea
          name='description'
          placeholder='Описание'
          value={data?.description || ''}
          onChange={handleChangeData}
        />
      </BlockModal>
      <BlockModal>
        <TitleModalBlock>Время</TitleModalBlock>
        <WrapperInputsTime>
          <TimePicker title='Длительность услуги (чч:мм)' value={data?.time} onChange={handleOnChangeTime('time')} />
          <TimePicker
            title='Технический перерыв (чч:мм)'
            value={data?.technical_break}
            onChange={handleOnChangeTime('technical_break')}
          />
          {/*<InputMack*/}
          {/*  valid={validData.time}*/}
          {/*  mask={'99:99'}*/}
          {/*  name='time'*/}
          {/*  placeholder='Длительность услуги (чч:мм)'*/}
          {/*  value={data?.time || ''}*/}
          {/*  onChange={handleChangeData}*/}
          {/*/>*/}
          {/*<BlockRight>*/}
          {/*  <InputMack*/}
          {/*    name='technical_break'*/}
          {/*    mask={'99:99'}*/}
          {/*    valid={validData.technical_break}*/}
          {/*    placeholder='Технический перерыв (чч:мм)'*/}
          {/*    value={data?.technical_break || ''}*/}
          {/*    onChange={handleChangeData}*/}
          {/*  />*/}
          {/*</BlockRight>*/}
        </WrapperInputsTime>
      </BlockModal>
      <BlockModal>
        <TitleModalBlock>Стоимость</TitleModalBlock>
        <WrapperBlock>
          <Switch
            title={'Стоимость зависит от условий'}
            active={false}
            // active={switchValue}
            // setActive={() => setSwitchValue((prev) => !prev)}
            setActive={() => undefined}
          />
        </WrapperBlock>
        <WrapperBlock>
          <RadioInput onClick={handledChangeRadio} value={radio} type={'fix_sum'} title='Фиксированная стоимость' />
          <WrapInput>
            <Input
              disabled={radio !== 'fix_sum'}
              placeholder='Стоимость'
              value={data?.price_fixed || ''}
              name='price_fixed'
              onChange={handleChangeData}
            />
          </WrapInput>
        </WrapperBlock>
        <WrapperBlock>
          <RadioInput
            onClick={handledChangeRadio}
            value={radio}
            type={'indefinite_sum'}
            title='Неопределенная стоимость'
          />
          <WrapInput>
            <WrapperInputs>
              <Input
                disabled={radio === 'fix_sum'}
                placeholder='От'
                value={priceDynamicFrom}
                onChange={(e) => handleClickPriceDynamic(e.target.value)}
              />
              <Line />
              <Input
                disabled={radio === 'fix_sum'}
                placeholder='До'
                value={priceDynamicTo}
                onChange={(e) => handleClickPriceDynamic('', e.target.value)}
              />
            </WrapperInputs>
          </WrapInput>
        </WrapperBlock>
      </BlockModal>
    </>
  );
};

const Line = styled.div`
  min-width: 26px;
  position: relative;
  &:after {
    content: '';
    background: var(--color-black-light);
    height: 1px;
    width: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
const WrapInput = styled.div`
  width: 198px;
`;
const WrapperBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
`;
const WrapperInputsTime = styled.div`
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;
const WrapperInputs = styled.div`
  display: flex;
  align-items: center;
`;
const TitleModalBlock = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: var(--color-black-light);
  margin-bottom: 16px;
`;
const WrapperSelector = styled.div`
  margin-top: 16px;
`;
const BlockRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-left: 16px;
`;
const BlockHead = styled.div`
  display: flex;
  align-items: center;
`;
const WrapperAddPhoto = styled.div`
  width: 96px;
`;
const BlockModal = styled.div`
  width: 510px;
  padding: 20px 24px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
